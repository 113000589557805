import React from 'react';
import Logo from '../../resources/images/loadingLogo.gif';

//DEFAULT STYLE
export function LOADING(props)
{
    const spinnerFront = {
        zIndex: 999,
        marginTop: '45vh',
        color: '#fff',
        textAlign: 'center',
        position: 'fixed',
        width: '100%',
        transition: 'all 1s',
        visibility: 'visible',
        opacity: 1,
      }
    
    const spinnerBack = {
        zIndex: 998,
        height: '100vh',
        background: '#000',
        position: 'fixed',
        width: '100%',
        transition: 'all 1s',
        visibility: 'visible',
        opacity: 0.7
    }

    if(props.theme === 'FULL_SCREEN')
    {
        return (
            <div>
                <div style={spinnerFront} className="d-flex align-items-center flex-column">
                    <img src={Logo} alt="" className="mb-3" style={{maxWidth: '300px'}}/>
                </div>
                <div style={spinnerBack}></div>
            </div>
        )
    }
    else if(props.theme === 'REGULAR')
    {
        return (
            <i className="fas fa-spinner fa-spin fa-pulse fa-5x" />
        )
    }
    else
    {
        return (
            <div>You must define a style</div>
        )
    }
};