import React, {useContext, useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { Button, Input} from 'reactstrap';

import {LoadingContext, AlertContext, userInfoContext, ModalContext} from '../../store';
import {lang} from '../../resources/languages';

import {EZFETCH} from '../../resources/modules/fetch';

function Payments()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);
    const [userInfo] = useContext(userInfoContext);
    const [, setModal] = useContext(ModalContext);

    //Local States
    const [paymentList, setPaymentList] = useState([]);
    const [dataStart, setDataStart] = useState(0);
    const [dataEnd] = useState(30);
    const [reachedMax, setReachedMax] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);

    //componentDidMount
    useEffect( () => {
       
        LoadPayments();
   
     }, []);


    //component will unmount 
    useEffect( () => {
       
        //Scrolling event
        window.addEventListener("scroll",  onScroll);

        //component will unmount
        return () => {
           
            window.removeEventListener("scroll", onScroll);
        }
   
     });
     
     
    async function LoadPayments()
    {
        if(isLoading) return;

        setLoading(true);

        try
        {

            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/payment/list`, 
                {dataStart: dataStart, dataEnd: dataEnd}
            );

            if(response[0] !== undefined)
            {
                //update offset
                setDataStart(dataStart + dataEnd);

                //append data
                setPaymentList(currentPaymentList => currentPaymentList.concat(response));
            }

            if(response[0] === undefined)
            {
                setReachedMax(true);

                setAlert({
                    visible: true,
                    position: 'topRightAlert',
                    message: lang('there_are_no_more_results'),
                    color: 'info'
                });
            }
        }
        catch(errors)
        {
            setAlert({
                visible: true,
                position: 'topRightAlert',
                message: lang('something_went_wrong'),
                color: 'danger'
            });
        }
        finally
        {
            setLoading(false);
        }
    }

    async function SearchPayment()
    {
        if(!searchQuery)
        {
            setAlert({
                visible: true,
                position: 'topRightAlert',
                message: lang('invalid_search_query'),
                color: 'danger'
            });

            return;
        }

        setLoading(true);

        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/payment/search`, 
                {searchQuery: searchQuery}
            );

            if(response[0] !== undefined) 
            {
                setPaymentList(response);
            }
    
            if(response[0] === undefined) 
            {
                setAlert({
                    visible: true,
                    position: 'topRightAlert',
                    message: lang('no_results_found'),
                    color: 'danger'
                });
            }
        }
        catch(errors)
        {
            setAlert({
                visible: true,
                position: 'topRightAlert',
                message: lang('something_went_wrong'),
                color: 'danger'
            });
        }
        finally
        {
            setLoading(false);
        }
    }

 

    const onScroll = async () =>
    {
        if(document.documentElement.scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight -20)
        {
            if(!reachedMax)
            {
                await LoadPayments();
            }
        }
    }

       
    return (

        <div className="headerSpace card shadow mt-5 min-vh-100">
            <div className="titleBg card-header">
                <div className="row">
                    <div className="col d-none d-lg-inline">
                        <h1 className="font-weight-bold">{lang('payments')}</h1>
                    </div>
                    <div className="col">
                        <div className="input-group input-group-lg">
                            <Input type="text" id="modelquery" className="form-control" placeholder={lang('search_by_transaction_id')} onChange={(e)=> setSearchQuery(e.target.value)}/>
                            
                            <div className="input-group-append">
                                <Button color="info" onClick={()=> SearchPayment()}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body min-vh-100">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{lang('transaction_id')}</th>
                                <th>{lang('status')}</th>
                                <th>{lang('email')}</th>
                                <th>{lang('name')}</th>
                                <th>{lang('product')}</th>
                                <th>{lang('amount')}</th>
                                <th>{lang('date')}</th>
                                <th>{lang('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                paymentList.map((payment, key) =>  (
                                    <tr key={key}>
                                        <td>{payment.txnid}</td>
                                        <td>{payment.status}</td>
                                        <td>{payment.email}</td>
                                        <td>{userInfo.details.fname} {userInfo.details.lname}</td>
                                        <td>{payment.itemid}</td>
                                        <td>{(payment.amount / 100).toFixed(2)}€</td>
                                        <td>{new Date(payment.date).toLocaleString("en-US")}</td>
                                        <td>
                                            <button 
                                                className="btn btn-info"
                                                onClick={()=> 
                                                    setModal({
                                                        show: true,
                                                        title: lang('invoice_sent'),
                                                        body: `${lang('the_invoice_was_sent_to')}: ${payment.email} ${lang('your_email_proviced')}`
                                                     })
                                                }>
                                                {lang('download')}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {paymentList[0] === undefined ? <h2 className="text-center font-weight_bold">{lang('you_have_no_payments_yet')}</h2> : ""}
                </div>
            </div>
        </div>
    );
   
}


export default Payments;