import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';// bootstrap CSS
import './resources/css/global.css'; //default css custom styles

import {
  BrowserRouter 
} from "react-router-dom";



ReactDOM.render(
  <Store>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Store>, 
  document.getElementById("APP")
);

