import React, {useContext, useState, useEffect} from 'react';
import {LoadingContext, AlertContext, ModalContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import '../../resources/css/emojiExperience.css';
import '../../resources/css/catalog.css';
import Rating from '../../resources/modules/rating';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import {flaticon} from '../../resources/modules/flaticon';
import classnames from 'classnames';

import {  
  Input, 
  Nav,
  NavItem,
  NavLink,
  TabContent,
  FormGroup, 
  CustomInput,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';



function Catalog()
{
  //Global states
  const [isLoading, setLoading] = useContext(LoadingContext);
  const [, setAlert] = useContext(AlertContext);
  const [activeTab, setActiveTab] = useState('1');
  const [globalModel, setGlobalModel] = useContext(ModalContext);

  const [modal, setModal] = useState({show: false, iconSelection: null});

 
  const [bizHeader, setBizHeader] = useState();
  const [recommendation, setRecommendation] = useState({});


  //icons
  const [icons, setIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState({icon1: null, icon2: null, icon3: null, icon4: null});


  useEffect( () => {

    setGlobalModel({
      show: true,
      title: "Aviso importante",
      body: "Esta es una seccion en version beta. Estamos trabajando para mejorarla."
    });

    getRecommendation();

    //temp
    iconList();

    

 }, []);


  const iconList = async () =>
 {
    setLoading(true);

    try
    {
      let resp = await flaticon.getIcons();

      setIcons(resp.data);
    }
    catch(e)
    {
      //do nothing for now
    }
    finally
    {
      setLoading(false);
    }
 }

 const searchIcon = async (e) => 
 {
   e.preventDefault();

   if(isLoading) return;

   if(!e.target.search.value) return;

   setLoading(true);

   try
   {

    let resp = await flaticon.searchIcon(e.target.search.value);

    if(!!resp) setIcons(resp.data);
    
   }
   catch(e)
   {

   }
   finally
   {
     setLoading(false);
   }
 }

 const saveSelectedIcon = (icon) =>
 {
  if(modal.iconSelection === 1)
  {
    setSelectedIcon({...selectedIcon, icon1: icon})
  }
  else if(modal.iconSelection === 2)
  {
    setSelectedIcon({...selectedIcon, icon2: icon})
  }
  else if(modal.iconSelection === 3)
  {
    setSelectedIcon({...selectedIcon, icon3: icon})
  }
  else if(modal.iconSelection === 4)
  {
    setSelectedIcon({...selectedIcon, icon4: icon})
  }
  
 }


  



 async function getRecommendation()
    {
      setLoading(true);

      try
      {
        let response = await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/system/getRecommendation`);

        setRecommendation(response);
      }
      catch(serverErrors)
      {
        setAlert({
          show: true,
          position: 'topRightAlert',
          message: lang('something_went_wrong'),
          color: 'danger'
        });
      }
      finally
      {
        setLoading(false);
      }
    }

  const addRecommendation = async (post) =>
  {
    post.preventDefault();

    if(isLoading) return;

    setLoading(true);

    try
    {
      await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/system/recommendation`,
        {
          title: !!post.target.title.value ? post.target.title.value : "N/A",
          recommendation: !!post.target.recommendation.value ? post.target.recommendation.value : "",
          recommendationIcon1: !!selectedIcon.icon1 ? selectedIcon.icon1 : "",
          recommendation1: !!post.target.recommendation1.value ? post.target.recommendation1.value : "",
          recommendationIcon2: !!selectedIcon.icon2 ? selectedIcon.icon2 : "",
          recommendation2: !!post.target.recommendation2.value ? post.target.recommendation2.value : "",
          recommendationIcon3: !!selectedIcon.icon3 ? selectedIcon.icon3 : "",
          recommendation3: !!post.target.recommendation3.value ? post.target.recommendation3.value : "",
          recommendationIcon4: !!selectedIcon.icon4 ? selectedIcon.icon4 : "",
          recommendationDescription: !!post.target.recommendationDescription.value ? post.target.recommendationDescription.value : "",
          recommendation1Description: !!post.target.recommendation1Description.value ? post.target.recommendation1Description.value : "",
          recommendation2Description: !!post.target.recommendation2Description.value ? post.target.recommendation2Description.value : "",
          recommendation3Description: !!post.target.recommendation3Description.value ? post.target.recommendation3Description.value : ""
        }
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });

      getRecommendation();
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('something_went_wrong'),
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }

  }



  async function updateImage() 
  {
    if(isLoading) return;
    
    if (!bizHeader)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang("please_select_an_image"),
        color: 'danger'
      });
    } 

    // Create an object of formData 
    var formData = new FormData();
    
    // Update the formData object 
    formData.append('catalog', bizHeader); 
       
    setLoading(true);

    
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    try
    {

      let resp = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/system/catalog`,
        formData,
        config
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: serverErrors.response.data,
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
    
  };


  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const deleteAllFields = async () => 
  {
    setLoading(true);

    try
    {
      let response = await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/system/deleteRecommendation`,
        {
          recommendationId:  recommendation.recommendationId
        }
      );

      setRecommendation({});

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: serverErrors.response.data,
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
  }

  const deleteCatalog= async () => 
  {
    setLoading(true);

    try
    {
      let response = await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/system/deleteCatalog`,
        {
          catalogId: recommendation.catalogId
        }
      );

      setRecommendation({});

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: serverErrors.response.data,
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
  }



    return (

        <div className="container-fluid">
            <div className="headerSpace card shadow mt-5">
                <div className="titleBg card-header">
                    <h1 className="font-weight-bold"> Recomendación y Catálogo</h1>
                </div>

                <div className="card-body min-vh-100">
                  <div className="container-fluid">


                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={`${classnames({ active: activeTab === '1' })} customPointer` }
                        onClick={() => { toggle('1'); }}>
                        
                        Recomendación
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={`${classnames({ active: activeTab === '2' })} customPointer` }
                        onClick={() => { toggle('2'); }}>
                        
                        Carta o Catálog PDF
                      </NavLink>
                    </NavItem>
                  </Nav>




                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="row m-3">
                          <div className="col">
                              <h2 className="font-weight-bold mt-3 text-center">Destaca tus promociones o recomendaciones del dia</h2>

                              <form className="mt-4" onSubmit={addRecommendation} noValidate>
                                  <div className="form-group">
                                    
                                      <div className="input-group text-center mb-4">
                                          <Input 
                                              type="text"
                                              name="title" 
                                              defaultValue={!!recommendation.title ? recommendation.title : "Titulo ej: Recomendacion del dia"}/>
                                      </div>
                                    
                                      <div className="row p-3">

                                          <div className="col">
                                              <div className="input-group mb-3">

                                                  <Input 
                                                    type="text"
                                                    name="recommendation" 
                                                    defaultValue={!!recommendation.recommendation ? recommendation.recommendation : "Escribir tu recomendacion"}/>


                                                  <div className="input-group-prepend">
                                                    <button type="button" className="btn btn-info rounded-right" onClick={() => setModal({show: true, iconSelection: 1})}>
                                                      {selectedIcon.icon1 ? <img src={selectedIcon.icon1} width="20" height="20"/> : !!recommendation.recommendationIcon1 ? <img src={recommendation.recommendationIcon1} width="20" height="20"/> : <FontAwesomeIcon icon={faImage} />}
                                                    </button> 
                                                  </div>

                                              </div>
                                              <textarea name="recommendationDescription" rows="5" className="form-control mb-3" maxLength="150" placeholder={recommendation.recommendationDescription ?? "escribe tu descripcion" } ></textarea>


                                              <div className="input-group mb-3">

                                                  <Input 
                                                    type="text"
                                                    name="recommendation1" 
                                                    defaultValue={!!recommendation.recommendation1 ? recommendation.recommendation1 : "Escribir tu recomendacion"}/>

                                                  <div className="input-group-prepend">
                                                    <button type="button" className="btn btn-info rounded-right" onClick={() => setModal({show: true, iconSelection: 3})}>
                                                    {selectedIcon.icon3 ? <img src={selectedIcon.icon3} width="20" height="20"/> : !!recommendation.recommendationIcon3 ? <img src={recommendation.recommendationIcon3} width="20" height="20"/> : <FontAwesomeIcon icon={faImage} />}
                                                    </button> 
                                                  </div>

                                              </div>
                                              <textarea name="recommendation1Description" rows="5" className="form-control mb-3" maxLength="150" placeholder={recommendation.recommendation1Description ?? "escribe tu descripcion" } ></textarea>

                                          </div>

                                          <div className="col">
                                              <div className="input-group mb-3">

                                                  <Input 
                                                    type="text"
                                                    name="recommendation2" 
                                                    defaultValue={!!recommendation.recommendation2 ? recommendation.recommendation2 : "Escribir tu recomendacion"}/>

                                                  <div className="input-group-prepend">
                                                    <button type="button"  className="btn btn-info rounded-right" onClick={() => setModal({show: true, iconSelection: 2})}>
                                                      {
                                                        selectedIcon.icon2 ? 
                                                        <img src={selectedIcon.icon2} width="20" height="20"/> : 
                                                        !!recommendation.recommendationIcon2 ? 
                                                        <img src={recommendation.recommendationIcon2} width="20" height="20"/> : 
                                                        <FontAwesomeIcon icon={faImage} />}
                                                    </button> 
                                                  </div> 

                                              </div>
                                              <textarea name="recommendation2Description" rows="5" className="form-control mb-3" maxLength="150" placeholder={recommendation.recommendation2Description ?? "escribe tu descripcion" } ></textarea>

                                              <div className="input-group mb-3">

                                                  <Input 
                                                    type="text"
                                                    name="recommendation3" 
                                                    defaultValue={!!recommendation.recommendation3 ? recommendation.recommendation3 : "Escribir tu recomendacion"}
                                                    />

                                                  <div className="input-group-prepend">
                                                    <button type="button" className="btn btn-info rounded-right" onClick={() => setModal({show: true, iconSelection: 4})}>
                                                    {selectedIcon.icon4 ? <img src={selectedIcon.icon4} width="20" height="20"/> : !!recommendation.recommendationIcon4 ? <img src={recommendation.recommendationIcon4} width="20" height="20"/> : <FontAwesomeIcon icon={faImage} />}
                                                    
                                                    </button> 
                                                  </div>

                                              </div>
                                              <textarea name="recommendation3Description" rows="5" className="form-control mb-3" maxLength="150" placeholder={recommendation.recommendation3Description ?? "escribe tu descripcion" } ></textarea>

                                          </div>
                                          
                                      </div>

                                      <div className="d-flex text-center">
                                          <button className="btn btn-info rounded-right" type="submit">Guardar</button>
                                      
                                          <button className="btn btn-danger rounded-right ml-2" type="button" onClick={() => deleteAllFields()}>Eliminar todo</button>
                                      </div>
                                  </div>
                              </form>

                              

                              <div className="d-flex mt-4">
                                  <FontAwesomeIcon className="text-info mr-1 mt-1" icon={faInfoCircle} />
                                  <p>Redisena la pagina publica de experiencia que ven tus clientes. Puedes cambiar el sector anteriormente seleccionado. Esto modificara la imagen de cabecera por defecto o puedes subir tu propia imagen de cabecera. <br/> <br/>Tambien puedes cambiar el emoji de experiencia positiva que representa tu negocio. </p>
                              
                              </div>

                          </div>

                          <div className="col">
                              <h2 className="font-weight-bold text-center mb-5">Vista previa</h2>
                              <p className="text-center">Rellena los campos de la izquierda para ver la vista previa</p>
                              
                              <div>
                                <h2 className="font-weight-bold text-center mb-5 mt-3">{recommendation.title}</h2>

                                <div className="row bg-white mb-3">
                                    <div className="col">
                                        <div>
                                            <h3 className="font-weight-bold">
                                              {recommendation.recommendation}

                                              {
                                                !!recommendation.recommendationIcon1 ? 
                                                  <img className="ml-1" src={recommendation.recommendationIcon1} width="20" height="20"/>
                                                :
                                                null
                                              }      

                                            </h3>

                                            {!!recommendation.recommendation ? <Rating rating="5" size="2"/>:null} 

                                            <p>{recommendation.recommendationDescription}</p>

                                            
                                        </div>

                                        <div>
                                            <h3 className="font-weight-bold">
                                              {recommendation.recommendation2}
                                              {
                                                !!recommendation.recommendationIcon2 ? 
                                                  <img className="ml-1" src={recommendation.recommendationIcon2} width="20" height="20"/>
                                                :
                                                null
                                              }  

                                              </h3>

                                              {!!recommendation.recommendation2 ? <Rating rating="5" size="2"/>:null} 

                                              <p>{recommendation.recommendation1Description}</p>
                                        </div>
                                    </div>

                                    <div className="col">

                                        <div>
                                            <h3 className="font-weight-bold">
                                              {recommendation.recommendation1}
                                              {
                                                !!recommendation.recommendationIcon3 ? 
                                                  <img className="ml-1" src={recommendation.recommendationIcon3} width="20" height="20"/>
                                                :
                                                null
                                              } 
                                              </h3>
                                            
                                            
                                              {!!recommendation.recommendation1 ? <Rating rating="5" size="2"/>:null} 


                                              <p>{recommendation.recommendation2Description}</p>

                                        </div>

                                        <div>
                                            <h3 className="font-weight-bold">
                                              {recommendation.recommendation3}
                                              {
                                                !!recommendation.recommendationIcon4 ? 
                                                  <img className="ml-1" src={recommendation.recommendationIcon4} width="20" height="20"/>
                                                :
                                                null
                                              } 
                                              
                                              </h3>

                                              
                                            
                                            
                                              {
                                                !!recommendation.recommendation3 ? 
                                                  <Rating rating="5" size="2"/>
                                                :
                                                null
                                              }

                                              <p>{recommendation.recommendation3Description}</p>
                                        </div>
                                    
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </TabPane>

                    <TabPane tabId="2">



                              *  <FormGroup>
                                <label className="font-weight-bold">Sube tu catálogo o carta en pdf</label>
                                <div className="input-group">
                                    
                                    <CustomInput 
                                        type="file" 
                                        name="myImage" 
                                        onChange={(e) => setBizHeader(e.target.files[0])} 
                                        label={lang('select_image')}
                                    />

                                    <div className="input-group-prepend">
                                        <button  className="btn btn-info rounded-right" onClick={() => updateImage()}>
                                            {lang('upload_image')}
                                        </button> 
                                        
                                        {
                                          !!recommendation.catalog ?

                                            <button className="btn btn-danger rounded-right ml-2" onClick={() => deleteCatalog()}>Eliminar Catalogo</button>
                                          : 
                                          null
                                        }
                                        
                                    </div>
                                </div>

                                
                            
                            </FormGroup>

                    
                                {!!recommendation.catalog ? 
                                  <object 
                                    frameborder="0"
                                    border="0"
                                    width="100%"
                                    height="900"
                                    toolbar="0"
                                    standby="Please wait while we load your catalog"
                                    type="application/pdf"
                                    data={`https://app.opinanow.com/catalog/${recommendation.catalog}`} allowfullscreen/>

                                  :

                                  null
                                }

                              
                    </TabPane>
                  </TabContent>




                   
                  </div>
                </div>

            </div>

          <Modal isOpen={modal.show} toggle={() => setModal({show: false})} centered="true" size="lg">
            <ModalHeader toggle={() => setModal({show: false})}>
                <h4 className="font-weight-bold">Selectiona un icono</h4>
            </ModalHeader>
            <ModalBody>
                
                <form onSubmit={searchIcon} noValidate>

                  <div className="input-group input-group-lg">
                      <Input 
                        type="text"
                        name="search"
                        className="form-control" 
                        placeholder="busca por nombre" />
                      
                      <div className="input-group-append">
                          <Button color="info" type="submit">
                              <FontAwesomeIcon icon={faSearch}/>
                          </Button>
                      </div>
                  </div>
                
                </form>

                <div className="d-flex flex-row flex-wrap pb-4 pt-4">

                  {
                    icons.map((icon, i) => (

                      <img 
                        onClick={()=> saveSelectedIcon(icon.images.png[512])}
                        style={{margin: '7px'}} 
                        className="iconHover"
                        key={i} 
                        src={icon.images.png[512]} />

                    ))
                  }
                </div>


               

            </ModalBody>
            <ModalFooter>

                <Button color="secondary" onClick={() => setModal({show: false})}>{lang('close')}</Button>
            </ModalFooter>
          </Modal>


        </div>
    )
}

export default Catalog;