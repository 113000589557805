import React, {useState, createContext} from 'react';

//Declare global context
const LoadingContext = createContext(); 
const userInfoContext = createContext();
const AlertContext = createContext();
const ModalContext = createContext();

//Store and set context
const Store = ({children}) => {

    const [isLoading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        isLogged: false, 
        details: {
            fname: null, 
            lname: null, 
            username: null, 
            email: null, 
            language: null, 
            isFirstTimeUser: null, 
            acl: null,
            lastGoogleUpdate: null}
    });

    const [showAlert, setAlert] = useState({show: false});
    const [modal, setModal] = useState({show: false});

    return (
        <LoadingContext.Provider value={[isLoading, setLoading]}>
        <userInfoContext.Provider value={[userInfo, setUserInfo]}>
        <AlertContext.Provider value={[showAlert, setAlert]}>
        <ModalContext.Provider value={[modal, setModal]}>
            {children}
        </ModalContext.Provider>
        </AlertContext.Provider>
        </userInfoContext.Provider>
        </LoadingContext.Provider>
    )
}


export default Store;
export {
    LoadingContext, 
    userInfoContext,
    AlertContext,
    ModalContext
};
