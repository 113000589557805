import React, {useState, useEffect, useContext} from 'react';
import {lang} from '../../resources/languages';
import { Doughnut, Bar} from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import {LoadingContext, AlertContext} from '../../store';
import {EZFETCH} from '../../resources/modules/fetch';
import Rating from '../../resources/modules/rating';


function DefaultDashboard()
{
    //Global states
    const [, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);
    const [selectDays, setSelectDays] = useState(0);

    //Local states
    const [dashData, setDashData] = useState({
        "clickCount" : {"positive" : 0, "positive_facebook" : 0, "negative": 0, "nulled" : 0},
        "socialCount" : {"facebook" : 0, "google" : 0},
        "reviews" : [],
        "reviewsCount" : {"oneStar": 0, "twoStars" : 0, "threeStars" : 0, "fourStars" : 0, "fiveStars": 0},
        "rating" : 0,
        "user_ratings_total" : 0
    });

    const totalVisits = dashData.clickCount.positive + dashData.clickCount.positive_facebook + dashData.clickCount.negative + dashData.clickCount.nulled;
    const ctrGoogle = (dashData.clickCount.positive * 100 / totalVisits);
    const ctrFacebook = (dashData.clickCount.positive_facebook * 100 / totalVisits);

    //start as 0
    useEffect(() => {

        InitDashboard(0); //cero is the default to show all the results

    }, []);


    async function InitDashboard(days)
    {
        setLoading(true);

        setSelectDays(days);//update select

        try
        {
            let res = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/system/dashboard`,
                {showResults: parseInt(days)}
            );

            setDashData(res);
        }
        catch(errors)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('an_error_has_ocurred_b'), 
                color: 'danger', 
                show: true                
            });
        }
        finally
        {
            setLoading(false);
        }
    }


    const data = {
        labels: [
            `${dashData.clickCount.positive} ${lang('positive_experience')}`,
            `${ dashData.clickCount.negative} ${lang('negative_experience')}`,
            `${ dashData.clickCount.nulled} ${lang('negative_nulled')}`
        ],
        datasets: [{
          data: [parseInt(dashData.clickCount.positive), parseInt(dashData.clickCount.negative), parseInt(dashData.clickCount.nulled)],
          backgroundColor: [
          '#568388',
          '#2B2639',
          '#fab02c',
          ],
          hoverBackgroundColor: [
          '#345a5f',
          '#000000',
          '#f0aa2d',
          ]
        }]
      };

      let googleRatingOptions = {
        scales: {
            yAxes: [{
                ticks: {
                    precision: 0,
                    max: 5,
                    min: 0,
                }
            }]
        }
    };

    const  googleRating = {
        labels: [
            lang('one_star'), 
            lang('two_star'),
            lang('three_star'),
            lang('four_star'),
            lang('five_star')
        ],
        datasets: [
            {
                label: lang('last_5_stars'),
                backgroundColor: '#ffa500',
                hoverBackgroundColor: '#de9104',
                data: [
                    parseInt(dashData.reviewsCount.oneStar), 
                    parseInt(dashData.reviewsCount.twoStars), 
                    parseInt(dashData.reviewsCount.threeStars), 
                    parseInt(dashData.reviewsCount.fourStars), 
                    parseInt(dashData.reviewsCount.fiveStars)
                ]            
            }
        ]
    };

    
    


    return (

        <div className="container-fluid">
            <div className="headerSpace card shadow mt-5">
                <div className="titleBg card-header row mr-0 ml-0">
                    <div className="col">
                        <h1 className="font-weight-bold">
                            {lang('general_experience')}
                        </h1>
                    </div>  
                </div>

                <div className="d-flex flex-row-reverse">
                        <div className="mr-5 mt-3">
                            <select
                                className="form-control"
                                value={selectDays}
                                onChange={(e) => InitDashboard(e.target.value)}>

                                <option value="0">{lang('show All')}</option>
                                <option value="7">{lang('7 days ago')}</option>
                                <option value="14">{lang('14 days ago')}</option>
                                <option value="28">{lang('30 days ago')}</option>
                            </select>
                        </div>
                </div>

                <div className="card-body min-vh-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="colOpt col-sm-6">
                                
                                <Doughnut data={data} />

                                <p className="text-center mt-3"><span className="font-weight-bold">{lang('total_views')}:</span> 
                                    {totalVisits}
                                </p>

                                <h2 className="mt-5">
                                    {lang('click_by_platform')}
                                </h2>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{lang('site')}</th>
                                            <th scope="col">{lang('clicks')}</th>
                                            <th scope="col">{lang('CTR')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr scope="row">
                                            <td>{lang('google')}</td>
                                            <td>{dashData.clickCount.positive >= 1 ? dashData.clickCount.positive : 0}</td>
                                            <td>{ctrGoogle !== 'Infinity' && ctrGoogle !== "NaN" ? ctrGoogle.toFixed(2) : '100'}%</td>
                                        </tr>
                                        <tr scope="row">
                                            <td>{lang('apple')}</td>
                                            <td>{dashData.clickCount.positive_facebook}</td>
                                            <td>{ctrFacebook !== 'Infinity' && ctrFacebook !== "NaN" ? ctrFacebook.toFixed(2) : '100'}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className="colOpt col-sm-6">
                                <h2>
                                    <FontAwesomeIcon icon={faStar} className="starColor"/> 
                                    {lang('google_my_business')} 
                                </h2>
                                
                                <div className="row mb-5">

                                <div className="ratingGMB col-sm p-3 m-3 p-3 bg-light rounded">

                                    <div className="d-flex justify-content-center">

                                        <div className="ratingNumber font-weight-bold h3 mt-2"> {dashData.rating}</div>
                                        <Rating rating={dashData.rating} size="3" />
                                    </div>

                                    <p className="text-center font-weight-bold">{lang('reviews_calification')}</p>
                                    </div>
                                    <div className="totalReviews col-sm m-3 p-3 bg-light rounded">
                                        <div className="font-weight-bold h3 text-center">{dashData.user_ratings_total}</div>
                                            
                                        <p className="text-center font-weight-bold">{lang('total_reviews')}</p>
                                    </div>

                                </div>

                                <Bar data={googleRating} options={googleRatingOptions} />

                            </div>
                        </div>

                        <div className="lastReviews row m-1">
                            <h2 className="mt-5">
                                {lang('last_5_reviews')}
                            </h2>

                            <div className="scrollEffect shadow p3">
                                {
                                    dashData.reviews.map((review, key) => (
                                        <div className="d-flex m-2" key={key}>

                                            <img 
                                                src={review.profile_photo_url} 
                                                style={{height: "50px"}} 
                                                className="ml-3 mt-3" 
                                                alt=""/>

                                            <div className="col">
                                                <h4 className="text-lg">
                                                    {review.author_name}
                                                </h4>
                                                <div className="ml-auto p-2">
                                <h6 class="lastRiviewsTime">{new Date(review.time).toLocaleString()}</h6>
                            </div>
                                            
                                                <Rating rating={review.rating} size="1.3"/>
                                                
                                                <p className="text-sm">
                                                    {review.text}
                                                </p>

                                            </div>
                                        </div>
                                    )) 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultDashboard;