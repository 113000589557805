import React, { useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {LoadingContext,  AlertContext} from '../../store';
import {EZFETCH} from '../../resources/modules/fetch';
import {lang} from '../../resources/languages';
import Logo from "../../resources/images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faUser, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';


function Resetpsw()
{
    //global
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    //local states
    const [errors, setErrors] = useState({}); //init as empty object
    const [inputType, setInputType] = useState({password: 'password', confirmPassword: 'password'});
    const [showForm, setShowForm] = useState('default');
    const [userEmail, setUserEmail] = useState(null);

    //browser history
    const history = useHistory();

    async function sendResetToken(post)
    {
        post.preventDefault();

        if(isLoading)  return;

        setLoading(true);

        setUserEmail(post.target.email.value);

        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/sendToken`, 
                { email: post.target.email.value }
            );

            if (response === 'email_token_sent')
            {
                setAlert({
                    position: 'topRightAlert',
                    message: lang('a_token_has_been_sent'), //translator key
                    color: 'success', 
                    show: true                
                });

                setShowForm('reset');
            }
        }
        catch (error)
        {
            setErrors(error);
        }
        finally 
        {
            setLoading(false);
        }
    }


    async function resetPassword(post)
    {
        post.preventDefault();

        if(isLoading) 
        {
            return;
        }

        setLoading(true);

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/resetpsw`, {
                    email: userEmail,
                    token: post.target.token.value,
                    password: post.target.password.value,
                    confirmPassword: post.target.confirmPassword.value
            });

            //redirect
            history.push({
               pathname: '/login', 
               message:  lang('your_psw_has_been_reset') 
            });
        }
        catch (error)
        {
            setErrors(error);
        }
        finally 
        {
            setLoading(false);
            setShowForm('form2');
        }
    }
    
       
    if(showForm === 'default')
    {
        return (
        
            <form onSubmit={sendResetToken} noValidate>

                <a href="https://opinanow.com">
                    <img src={Logo} alt="logo" className="img-fluid mb-3 align-self-center"/>
                </a>

                <h5 className="text-center text-white m-3">{lang('reset_psw_title')}</h5>

                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faAt} />
                            </span>
                        </div>
                        <input 
                            type="email"
                            name="email" 
                            placeholder={lang('email_address')}  
                            className={`${errors.email ? 'is-invalid' : ''} form-control rounded-right border-0 shadow-none`} />
                        <div {...errors.email ? 'invalid' : ''} className="invalid-feedback">{errors.email}</div>
                    </div>
                </div>

                <div className="text-center d-flex flex-column">
                    <button type="submit" className="btn btn-info btn-lg">
                        <FontAwesomeIcon icon={faUser} className="mr-2"/>
                        {lang('reset_password')}
                    </button>

                    <Link to="/login" className="mt-5 mb-2 text-white">{lang('login')}</Link>
                </div>
                
            </form>
        );
    }
    else
    {
        return (
        
            <form onSubmit={resetPassword} noValidate>

                <img src={Logo} alt="" className="img-fluid mb-3 align-self-center"/>

                <h5 className="text-center text-white m-3">{lang('reset_psw_title')}</h5>

                <div className="floatingLabel">
                    <input 
                        type="text"
                        name="email" 
                        value={userEmail}
                        disabled
                        className="form-control" />
                    <label for="email">{lang('email_address')}</label>
                </div>

                <div className="floatingLabel">
                    <input 
                        type="number"
                        name="token" 
                        placeholder={lang('token')}  
                        className="form-control" />
                    <label for="token">{lang('token')}</label>
                    <div {...errors.token ? 'invalid' : ''} className="invalid-feedback">{errors.token}</div>
                </div>
    

                <div className="floatingLabel">
                    <input 
                        type={inputType.password} 
                        name="password" 
                        placeholder={lang('password')}  
                        className={`${errors.password ? 'is-invalid' : ''} form-control  passwordInputFeedback`} />
                    <label for="password">{lang('password')}</label>
                    
                    <span 
                        className="passwordInput"
                        onClick={() => inputType.password === 'password' ? 
                            setInputType({password: 'text', confirmPassword: inputType.confirmPassword}) : 
                            setInputType({password: 'password', confirmPassword: inputType.confirmPassword})}>  
                        {inputType.password === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </span>               

                    <div {...errors.password ? 'invalid' : ''} className="invalid-feedback">{errors.password}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type={inputType.confirmPassword} 
                        name="confirmPassword" 
                        placeholder={lang('confirmPassword')}  
                        className={`${errors.confirmPassword ? 'is-invalid' : ''} form-control passwordInputFeedback`} />
                    <label for="confirmPassword">{lang('confirmPassword')}</label>
                    
                    <span 
                        className="passwordInput"
                        onClick={() => inputType.confirmPassword === 'password' ? 
                        setInputType({confirmPassword: 'text', password: inputType.password}) : 
                        setInputType({confirmPassword: 'password', password: inputType.password})}>  
                        {inputType.confirmPassword === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </span>               

                    <div {...errors.confirmPassword ? 'invalid' : ''} className="invalid-feedback">{errors.confirmPassword}</div>
                </div>
                
                <div className="text-center d-flex flex-column">
                    <button type="submit" className="btn btn-info btn-lg">
                        <FontAwesomeIcon icon={faUser} className="mr-2"/>
                        {lang('change_password')}
                    </button>

                    <Link to="/login" className="mt-5 mb-2 text-white">{lang('login')}</Link>
                </div>
                
            </form>
        );
    }

}

export default Resetpsw;