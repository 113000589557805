import {EZFETCH} from '../modules/fetch';

/**
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 * Xtreme Software Solutions LLC @2020
 * FrontEnd MultiLanguage Module v0.1
 * Coded By @jerryurenaa <{@!@}>
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 */

/**
 * Dictionary carrier
 * configure language with default frontend locale
 */
let dictionary = require(`./${process.env.REACT_APP_DEFAULT_LANGUAGE_LOCALE}.json`);


/**
 * @method currentLanguage
 * @return string locale
 * this function will get and set current server language locale
 */
async function currentLanguage()
{
    try
    {
        let serverLang = await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/system/currentlanguage`);

        dictionary = require(`./${serverLang}.json`);

        return serverLang;
    }
    catch (errors)
    {
        dictionary = require(`./${process.env.REACT_APP_DEFAULT_LANGUAGE_LOCALE}.json`);

        return process.env.REACT_APP_DEFAULT_LANGUAGE_LOCALE;
    }
}


/**
 * @method setLanguageDictionary
 * @param  locale 
 * This method will set current language dictionary
 */
async function setLanguageDictionary(locale)
{
    await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/system/language`, 
        {language: locale}
        
    );

    dictionary = require(`./${locale}.json`);
}


/** 
 * @method lang
 * @param string key
 * @return string translation  
 */
function lang(key)
{
    return dictionary[key];
}


export { currentLanguage, setLanguageDictionary, lang};