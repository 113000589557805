import React, {useContext, useState} from 'react';
import {userInfoContext, LoadingContext, AlertContext} from '../../store';
import { lang } from '../../resources/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { Collapse, CardBody, Input, Card, CardHeader, FormGroup, FormFeedback} from 'reactstrap';
import {EZFETCH} from '../../resources/modules/fetch';


function Support()
{
    //Global states
    const [userInfo] = useContext(userInfoContext);
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    //local states
    const [toggleQuestion, setToggequestion] = useState(1);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    
    //Frequently asked questions.
    let frequentQuestions = [
        {id: 1, title: lang('response_time'), answer: lang('response_time_a')},
        {id: 2, title: lang('can_i_call'), answer: lang('can_i_call_a')},
        {id: 3, title: lang('how_can_i_modify_my_account'), answer: lang('how_can_i_modify_my_account_a')}
    ];


    async function contactus(post)
    {
        post.preventDefault();

        if(isLoading) return;

        if(!message) return;

        if(message.length  < 100) {
            setErrors({message: lang('your_message_must_be_100_chars')});
            return;
        }

        let params = {
            fname: post.target.fname.value,
            email: post.target.email.value,
            message: message
        }

        setLoading(true);

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/system/contact`, 
                params
            );

            setAlert({
                show: true,
                position: 'topRightAlert',
                message: lang('we_have_received_your_message'),
                color: 'success'
            });

            //cleaar value
            setMessage('');
        }
        catch(serverErrors)
        {
            setAlert({
                show: true,
                position: 'topRightAlert',
                message: lang('something_went_wrong'),
                color: 'danger'
            });
        }
        finally
        {
            setLoading(false);
        }
    }


    return(

        <div className="container-fluid">
            <div className="headerSpace card shadow mt-5">
                <div className="titleBg card-header">
                    <h1 className="font-weight-bold">
                        {lang('support')}
                    </h1>
                </div>

                <div className="card-body min-vh-100">
                    <div className="row">
                        <div className="colOpt col-sm-6">
                            <h2 className="text-center mb-3">
                                {lang('frequent_questions')}
                            </h2>

                            {
                                frequentQuestions.map(function (question, key) 
                                {
                                    return (

                                        <Card key={key} className="mb-3">
                                            <CardHeader onClick={() => setToggequestion(question.id)}>
                                                <span className="font-weight-bold">
                                                    {question.title}
                                                </span>
                                            </CardHeader>
                                            <Collapse  isOpen={toggleQuestion === question.id ? true : false}>
                                                <CardBody>
                                                    {question.answer}
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    ) 
                                })
                            }
                           
                        </div>

                        <div className="colOpt col-sm-6">
                            <form className="bg-light p-4 rounded" onSubmit={contactus} noValidate>
                                <h2 className="text-center">{lang('contact_us')}</h2>

                                <FormGroup>
                                    <label className="font-weight-bold">{lang('fname')}</label>
                                    <Input type="text" name="fname" value={userInfo.details.fname}  disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <label className="font-weight-bold">{lang('email')}</label>
                                    <Input type="email" name="email" value={userInfo.details.email}   disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <label className="font-weight-bold">{lang('message')}</label>
                                    <Input type="textarea" invalid={errors.message ? true : false} rows="10" value={message} onChange={(e) => setMessage(e.target.value)}/>
                                    <FormFeedback invalid={errors.message ? 'true' : ''}>{errors.message}</FormFeedback>
                                </FormGroup>

                                <div className="text-center">
                                    <button type="submit" className="btn btn-info btn-lg">
                                        <span className="mr-1">{lang('send_btn')}</span> 
                                        <FontAwesomeIcon icon={faPaperPlane}/>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support;