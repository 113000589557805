import React, {useState, useEffect, useContext} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import {useHistory} from "react-router-dom";
import {LoadingContext, userInfoContext} from '../../store';

function IdleMonitor()
{
  //Global state
  const [, setLoading] = useContext(LoadingContext);
  const [, setUserInfo] = useContext(userInfoContext);

  //Modal
  const [idleModal, setIdleModal] = useState(false);

  //Browser history
  const history = useHistory();

  let idleTimeout = 1000 * 60 * 45;  //15 minutes
  let idleLogout = 1000 * 60 * 10; //5 Minutes
  let idleEvent; 
  let idleLogoutEvent;

  /**
   * Add any other events listeners here
   */
  const events = [
    'mousemove',
    'click',
    'keypress'
  ];


  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => 
  {  
    if(!!idleEvent) clearTimeout(idleEvent);
    if(!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
    //if(idleModal) setIdleModal(false); 
    
    idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
    idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
   
  };

  /**
   * @method extendSession
   * This function will extend current user session.
   */
  const extendSession = async () => 
  {
    setLoading(true);
    setIdleModal(false); 

    try
    {
      await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/user/info`);
    }
    catch (errors)
    {
      //redirect to login
      history.push('/login');
    }
    finally
    {
      setLoading(false);
    }
  }


  /**
   * @method logOut
   * This function will destroy current user session.
   */
  const logOut = async () => 
  {

    setIdleModal(false); 
    setLoading(true);

    try
    {
      await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/user/logout`);

      localStorage.clear();

      setUserInfo({isLogged: false, details: {}});

      history.push('/login');
    }
    catch(errors) 
    {
      history.push('/login');
    }
    finally
    {
      setLoading(false);
    }
  }

  useEffect(() => 
  {
    for (let e in events) 
    {
      window.addEventListener(events[e], sessionTimeout);
    }

    return () => 
    {
      for(let e in events)
      {
        window.removeEventListener(events[e], sessionTimeout);
      }
    }
  },[]);


  return (

    <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
      <ModalHeader toggle={() => setIdleModal(false)}>
        {lang('session_expired_warning')}
      </ModalHeader>
      <ModalBody>
          {lang('your_session_will_expire_in')} {idleLogout / 60 / 1000} {lang('do_you_want_to_extend_session')}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info"  onClick={()=> logOut()}>{lang('logout')}</button>
        <button className="btn btn-info" onClick={()=> extendSession()}>{lang('extend_session')}</button>
      
      </ModalFooter>
    </Modal>
  )

  }

export default IdleMonitor;