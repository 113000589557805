import React, {useEffect, useState, useContext} from 'react';
import { Doughnut, Line} from 'react-chartjs-2';
import {LoadingContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import {useHistory} from "react-router-dom";
import {Modal, ModalHeader, ModalBody,  Input, FormGroup, FormFeedback} from 'reactstrap';
import Rating from '../../resources/modules/rating';
import '../../resources/css/negativeFeedback.css';

import UserProfile from '../../resources/images/userprofile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function Negativefeedback()
{

  //Global states
  const [isLoading, setLoading] = useContext(LoadingContext);
  const [, setAlert] = useContext(AlertContext);

  //Local States
  const [feedbackInfo, setFeedbackInfo] = useState({negativeReviews: [], respondedNegativeReviews: [], totalNegative: 0, totalNegativeResponded: 0, totalReviewsWithoutUs: 0, rating: 0});
  const [modal, setModal] = useState({show: false, info: {name: null, email: null, review: null, date: null}});
  const [errors, setErrors] = useState({});


  const data = {
    labels: [
      `${feedbackInfo.totalNegativeResponded}  ${lang('responded_a')}`,
      `${feedbackInfo.totalNegative - feedbackInfo.totalNegativeResponded} ${lang('not_responded')}`
    ],
    datasets: [{
      data: [parseInt(feedbackInfo.totalNegativeResponded), parseInt(feedbackInfo.totalNegative)],
      backgroundColor: [
      '#568388',
      '#2B2639',
      ],
      hoverBackgroundColor: [
      '#345a5f',
      '#000000',
      ]
    }]
  };

  const dataChart = {
    
    datasets: [
      {
        label: lang('google_my_business_a'),
        fill: true,
        zIndex: 0,
        borderColor: '#568388',
        data: [feedbackInfo.rating, feedbackInfo.rating]
      },

      {
        label: lang('negative_inpact'),
        fill: true,
        zIndex: 1,
        backgroundColor: '#ffa50096',
        borderColor: '#ffa400',
        data: [feedbackInfo.totalReviewsWithoutUs, feedbackInfo.totalReviewsWithoutUs]
      }
    ]
  };


  let googleRatingOptions = {
    scales: {
        yAxes: [{
            ticks: {
                precision: 0,
                max: 5,
                min: 1,
          }
      }]
    }
  };


  //componentDidMount
  useEffect( () => {

    const getNegativeFeedbackInfo = async () =>
    {
      setLoading(true);

      try
      {
        let response = await EZFETCH.get(
            `${process.env.REACT_APP_SERVER_URL}/system/negativefeedback`,
        );

        setFeedbackInfo(response);
      }
      catch(serverErrors)
      {
        setAlert({
          position: 'topRightAlert',
          message: lang('something_went_wrong'), 
          color: 'danger', 
          show: true                
        });
      }
      finally
      {
        setLoading(false);
      }
    }

      
    getNegativeFeedbackInfo();


  }, []);

  
  const handleRespondFeedback = async (post) =>
  {
    if(isLoading) return;
    post.preventDefault();

    let params = {
      message: post.target.message.value,
      reviewID: modal.info.id
    }

    setLoading(true);
    
    try
    {
      await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/system/respondfeedback`,
        params
      );

      setModal({show: false, info: modal.info});

      setAlert({
        position: 'topRightAlert',
        message: lang('your_response_has_been_sent'), 
        color: 'success', 
        show: true                
      });

        let response = await EZFETCH.get(
            `${process.env.REACT_APP_SERVER_URL}/system/negativefeedback`,
        );

        setFeedbackInfo(response);
    }
    catch(errors)
    {
      setAlert({
        position: 'topRightAlert',
        message: lang('something_went_wrong'), 
        color: 'danger', 
        show: true                
      });
    }
    finally
    {
      setLoading(false);
    }
  }


  return (

    <div className="headerSpace card shadow mt-5">
      <div className="titleBg card-header">
        <h1 className="font-weight-bold">
          {lang('negative_experience_a')}
        </h1>
      </div>

      <div className="neContainer card-body min-vh-100 d-flex flex-column">
        {feedbackInfo.totalNegative >= 1 ?

          <div>
            <div className="row">
            
              <div className="colNegativeE col">
                <Doughnut data={data} />

                <p className="text-center mt-3">{lang('total_negatives')}: {feedbackInfo.totalNegative}</p>
              </div>

              <div className="colRating col">
                <h2 className="text-center">
                  {lang('protected_online_reputation')}
                </h2>

                <div class="ratingCS d-flex flex-row justify-content-center">

                  <div className="bg-light mr-3 rounded p-2">
                    <h4 className="text-center">
                      {lang('with_opinanow')}
                    </h4>

                    <div className="d-flex">

                      <Rating rating={feedbackInfo.rating} size="3" />

                      <div className="font-weight-bold h3 mt-2"> {feedbackInfo.rating}</div>
                    </div>
                  </div>
                  
                  <div className="bg-light rounded p-2">
                    <h4 className="text-center">
                      {lang('without_opinanow')}
                    </h4>

                    <div className="d-flex">

                      <Rating rating={feedbackInfo.totalReviewsWithoutUs} size="3" />

                      <div className="font-weight-bold h3 mt-2"> {feedbackInfo.totalReviewsWithoutUs}</div>
                    </div>
                  </div>
                </div>

                <Line data={dataChart} options={googleRatingOptions} />

              </div>
            </div>

            <div className="reviewsNoResp pl-5 pr-5">
              <h2 class="mt-3">
                {lang('Negative_unanswered_reviews')}
              </h2>
              <div className="container-fluid scrollEffect shadow p-3">
                {
                  feedbackInfo.negativeReviews.map((negative, key) => negative.isresponded == 0 ? (
                    <div className="d-flex m-2 border-bottom border-dark" key={key}>

                      <img 
                          src={UserProfile}
                          style={{height: "50px"}} 
                          className="mr-3 mt-3" 
                          alt=""/>

                      <div style={{width: "90%"}}>
                        <div className="d-flex">
                            <div className="p-2">
                                <h4 className="text-lg">{negative.name}</h4>
                            </div>
                            <div className="ml-auto p-2">
                                <h6 class="font-weight-bold">{new Date(negative.date).toLocaleString()}</h6>
                            </div>
                        </div>
                    
                        <Rating rating="1" size="1.3"/>
                        
                        <p className="text-sm">
                          {negative.review}
                        </p>

                        {
                          negative.email ? 

                          <button className="btn btn-warning mb-3 text-white" onClick={()=> setModal({show: true, info: negative})}>
                            <FontAwesomeIcon icon={faPencilAlt}/> {lang('respond')}</button>

                          :

                          <div className="d-flex mt-4">
                            <FontAwesomeIcon className="text-info mr-1 mt-1" icon={faInfoCircle} />
                            <span className="mb-3">{lang('this_review_cannot_be_answered')}</span>
                          </div>

                        }

                      </div>
                    </div>
                  ) : "")
                }
                
              </div>
            </div>

            {feedbackInfo.respondedNegativeReviews[0] !== undefined ? 
              <div className="reviewsResp pl-5 pr-5 mt-5">
                <h2 class="mt-3">
                  {lang('negative_reviews_answered')}
                </h2>
                <div className="container-fluid scrollEffect shadow p-3">
                  {
                    feedbackInfo.respondedNegativeReviews.map((respondedNegative, key) =>  (
                      <div className="d-flex m-2 border-bottom border-dark" key={key}>
      
                        <img 
                            src={UserProfile}
                            style={{height: "50px"}} 
                            className="mr-3 mt-3" 
                            alt=""/>
      
                        <div style={{width: "90%"}}>
                            <div className="d-flex">
                                <div className="p-2">
                                    <h4 className="text-lg">{respondedNegative.name}</h4>
                                </div>
                                <div className="ml-auto p-2">
                                    <h6 class="font-weight-bold">{new Date(respondedNegative.dateResponded).toLocaleString()}</h6>
                                </div>
                            </div>
                      
                            <Rating rating="1" size="1.3"/>
                            
                            <p className="text-sm">
                                {respondedNegative.review}
                            </p>
        
                            <div className="d-flex flex-column mt-4">
                                <h6 className="font-weight-bold mb-3">{lang('your_response')} {new Date(respondedNegative.date).toLocaleString()}</h6>
                                <p className="border-left border-dark ml-3 pl-1">
                                    {respondedNegative.message}
                                </p>
                            </div>
    
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            : ""}

          </div>
        :
          
          <h2 className="text-center">{lang('there_are_no_negative_experiences')}</h2>
        }
      </div>

      <Modal
          isOpen={modal.show}
          toggle={() => setModal({show: false, info: modal.info})}
          scrollable={true}>

          <ModalHeader toggle={() => setModal({show: false, info: modal.info})}>
              <h2>{lang('respond_modal_title')}</h2>
          </ModalHeader>

          <ModalBody>
            <form onSubmit={handleRespondFeedback} noValidate>

              <p>{lang('name')}: {modal.info.name}</p>
              <p>{lang('review')}: {modal.info.review}</p>
              <p>{lang('email')}: {modal.info.email}</p>
              <p>{lang('date')}: {modal.info.date}</p>

              <FormGroup>
                <label>{lang('respond')}</label>
                  <Input type="textarea" name="message" rows="10" placeholder="dejale un mensaje a esta persona"/>
              </FormGroup>

              <button type="submit"  className="btn btn-info">{lang('respond')}</button>
       
            </form>
          </ModalBody>
      </Modal>

    </div>
  )
}

export default Negativefeedback;
