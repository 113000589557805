export const builder = require('./builder.png');
export const computer = require('./computer.png');
export const dentist = require('./dentist.png');
export const designer = require('./designer.png');
export const doctor = require('./doctor.png');
export const engineer = require('./engineer.png');
export const lazer_hair_removal = require('./lazer_hair_removal.png');
export const hairdresser = require('./hairdresser.png');
export const hairdressing = require('./hairdressing.png');
export const ice_cream_maker = require('./ice_cream_maker.png');
export const japanese_chef = require('./japanese_chef.png');
export const massage = require('./massage.png');
export const mechanic = require('./mechanic.png');
export const normal_chef = require('./normal_chef.png');
export const painter = require('./painter.png');
export const photographer = require('./photographer.png');
export const pizza = require('./pizza.png');
export const police = require('./police.png');
export const professor = require('./professor.png');