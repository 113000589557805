
import React, {useContext, useState, useEffect} from 'react';
import {lang} from '../../resources/languages';
import {Switch, Route, useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {LoadingContext,  userInfoContext, AlertContext, ModalContext} from '../../store';
import {hideRecaptchaV3Badge} from '../../resources/modules/recaptcha';
import {LOADING} from '../parts/loading';
import IdleMonitor from '../parts/idlemonitor';
import '../../resources/css/app.css';
import {EZFETCH} from '../../resources/modules/fetch';

//components
import FirstTimeUser from './firstTimeUser';
import Support from './support';
import Mybusiness from './mybusiness';
import Payments from './payments';
import Account from './account';
import Emojiexperience from './emojiexperience';
import Negativefeedback from './negativefeedback';
import DefaultDashboard from './DefaultDashboard';
import Catalog from './catalog';

import { 
    Navbar, 
    Nav,  
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, 
    Alert,
    UncontrolledDropdown, 
    DropdownToggle, 
    DropdownItem, 
    DropdownMenu  } from 'reactstrap';

import { 
    faBars, 
    faHandHoldingHeart,
    faUser, 
    faSignOutAlt, 
    faUserCircle, 
    faArrowLeft, 
    faArrowRight, 
    faWindowClose,
    faAngleUp, faChartLine, faBuilding, faCommentDots, faSmileBeam, faCreditCard, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

//resources
import Logo from '../../resources/images/logo.svg';



function Dashboard()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [userInfo, setUserInfo] = useContext(userInfoContext);
    const [showAlert, setAlert] = useContext(AlertContext);
    const [modal, setModal] = useContext(ModalContext);

    //Local states
    const [menuStyle, setMenuStyle] = useState('side-menu');
    const [scrollToTop, setScrollToTop] = useState('none');
    const [signOut, setSignOut] =  useState(false);


    //Browser history
    const history = useHistory();

    const dateLocale = () => {

        if(userInfo.details.language == "en")
        {
            return "en-US";
        }

        return "es-ES";
    }

    useEffect(() => {
        if(window.innerWidth <= 576)
        {
            setMenuStyle('more-mobile-show');
        }
        if(window.innerWidth >= 576)
        {
            setMenuStyle('side-menu-toggled');
        }

        if(window.innerWidth <= 1350)
        {
            setMenuStyle('side-menu-toggled');
        }

        if(window.innerWidth >= 1350)
        {
            setMenuStyle('side-menu');
        }
    }, [])

    

    useEffect(() => {

        

        hideRecaptchaV3Badge();

        let alertTimeout = setTimeout(() => setAlert({show: false}), 15 * 1000);


        return () => {
            clearTimeout(alertTimeout);
        }

    },[showAlert]);


    /**Resize  */
    useEffect(() => {
        
        window.addEventListener("resize", resizeScreen);
        window.addEventListener("scroll", onScroll);

        return () => {

            window.removeEventListener("resize", resizeScreen);
            window.removeEventListener("scroll", onScroll);
        }
        
    });


    useEffect(()=> {

        //parse url params
        const search = history.location.search; 
        const params = new URLSearchParams(search);
        const message = params.get('message'); 


        //Param message
        if(history.location.message !== undefined || message !== null)
        {
            const getMessage = history.location.message !== undefined ? history.location.message : message; 

            setAlert({
                position: 'topRightAlert',
                message: getMessage,
                color: 'success', 
                show: true                
                }
            );
        };

    }, [history.location.message]);


    /*Screen resize */
    const resizeScreen = () =>
    {
        if(window.innerWidth <= 576)
        {
            setMenuStyle('more-mobile-show');
        }
        if(window.innerWidth >= 576)
        {
            setMenuStyle('side-menu-toggled');
        }

        if(window.innerWidth <= 1350)
        {
            setMenuStyle('side-menu-toggled');
        }

        if(window.innerWidth >= 1350)
        {
            setMenuStyle('side-menu');
        }
        
    }

  

    /*On scroll */
    const onScroll = ()=>
    {        
        //If scroll show back to top
        if(document.documentElement.scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight -20)
        {
            //show back to top
            setScrollToTop('inline');
        }

        //if page scroll is back to 0
        if(window.scrollY === 0)
        {
            //hide back to top
            setScrollToTop('none');
        }
    }

    /*Back to top */
    const scrollTop = () => 
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    //Menu click middleware
    const HandleMenuClick = (url) =>
    {
        if(userInfo.details.isFirstTimeUser === 1)
        {
           setModal({
               show: true,
               title: lang('you_must_have_an_active_subs'),
               body: lang('finalize_your_registration')
            });
        }
        else
        {
            history.push(url);
        }
    }

    const logOut = async () =>
    {
        if(isLoading) return;

        //Set to false to prevent the modal from auto initiating on next login
        setModal({show: false});

        setLoading(true);

        try
        {
            await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/user/logout`);

            localStorage.clear();

            setUserInfo({isLogged: false, details: {}});

            history.push('/login');
        }
        finally 
        {
            setLoading(false);
        }
    }


    // const getCorrectDate = () => 
    // {
    //     // var date = new Date();

    //     // if(Number.isNaN(date.getMonth())) 
    //     // {
    //     //     let arr = userInfo.details.lastGoogleUpdate.split(/[- :]/);
    //     //     date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    //     // }

    //     // var day = date.getDay(); //
    //     // var month = date.getMonth() + 1;
    //     // var year = date.getFullYear();
   
    //     // //Alert.alert(date + '-' + month + '-' + year);
    //     // // You can turn it in to your desired format
    //     // return day + '/' + month + '/' + year;//format: d-m-y;

    //     return ;
    // }




    return (
        <div id="wrapper">

            {isLoading ? <LOADING theme="FULL_SCREEN" /> : ''}

            <Alert
                className={showAlert.position + " globalAlert"}
                isOpen={showAlert.show? true : false}
                color={showAlert.color}
                toggle={() => setAlert({show: false})}>

                <p>{showAlert.message}</p>
            </Alert>

            <IdleMonitor/>
                
            <ul className={`navbar-nav ${menuStyle}`}>
                
                <img 
                    src={Logo} 
                    alt="" 
                    className="logo-brand" 
                    onClick={() => HandleMenuClick("/")}/>                    
            
                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/")}>
                        <FontAwesomeIcon icon={faChartLine} />
                        <span>{lang('dashboard')}</span>
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/mybusiness")}>
                        <FontAwesomeIcon icon={faBuilding} />
                        <span>{lang('my_business_a')}</span>
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/negativefeedback")}>
                        <FontAwesomeIcon icon={faCommentDots} />
                        <span>{lang('negative_feedback')}</span>
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/emojiexperience")}>
                        <FontAwesomeIcon icon={faSmileBeam} />
                        <span>{lang('emoji_experience_a')}</span>
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/catalog")}>
                        <FontAwesomeIcon icon={faHandHoldingHeart} />
                        <span>Recomendaciones</span>
                    </button>
                </li>
               
                {/* <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/payments")}>
                        <FontAwesomeIcon icon={faCreditCard} />
                        <span>{lang('payments_a')}</span>
                    </button>
                </li> */}

                <li className="nav-item">
                    <button className="nav-link buttonToLink" onClick={() => HandleMenuClick("/support")}>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span>{lang('support_a')}</span>
                    </button>
                </li>

                <div className="toggle-side-menu-button">
                    <Button 
                        color="info" 
                        className="rounded-circle border-0" 
                        onClick={() => setMenuStyle(menuStyle === 'side-menu-toggled' ? 'side-menu' : 'side-menu-toggled')}> 
                        <FontAwesomeIcon icon={menuStyle === 'side-menu-toggled' ?  faArrowRight : faArrowLeft} />
                    </Button>
                </div>

                <div className="mobileicon mt-2">
                    <button 
                        className="btn text-white shadow-none" 
                        onClick={() => setMenuStyle(menuStyle === 'more-mobile-show' ?  'side-menu-toggled':  'more-mobile-show')}>

                        {menuStyle === 'more-mobile-show'? <FontAwesomeIcon icon={faWindowClose} /> : <FontAwesomeIcon icon={faBars}/>}
                        <span>{menuStyle === 'more-mobile-show'? "Close": "More"}</span>
                    </button>
                </div>
            </ul>

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar light expand="md" className="shadow bg-white">
                        {userInfo.details.isFirstTimeUser === 0 ?
                            <p> 
                                {lang('last_updated')}:  
                                <span className="font-weight-bold ml-1"> 
                                    {userInfo.details.lastGoogleUpdate}
                                </span>
                            </p>  
                        : ""}                 

                        <Nav className="ml-auto" navbar>
                            <p className="mr-2 d-none d-lg-inline py-2">
                                {lang('welcome')},  
                              <span> {userInfo.details.fname}</span>
                            </p>
                                
                            <UncontrolledDropdown>
                                <DropdownToggle nav  tag="a" >
                                    <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                                </DropdownToggle>

                                <DropdownMenu className="mt-2">
                                    <DropdownItem onClick={() => history.push("/account")}>
                                        <FontAwesomeIcon icon={faUser} size="sm" className="mr-2"/>
                                        <span>{lang('account_settings')}</span>
                                    </DropdownItem>

                                    <DropdownItem 
                                        onClick={() => { 
                                            setSignOut(true);
                                            setModal({show: true, title: lang('ready_to_leave'), body: lang('press_signout_to_logout')})}}>
                                    <FontAwesomeIcon icon={faSignOutAlt} size="sm" className="mr-2"/>
                                        <span>{lang('logout')}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Navbar>

                    <div className="container-fluid min-vh-100">

                        {
                            userInfo.details.isFirstTimeUser === 1 ?

                            <Switch> 
                                <Route exact path="/" component={FirstTimeUser}/>
                                <Route exact path="/canceled" component={FirstTimeUser}/>
                                <Route exact path="/success" component={FirstTimeUser}/>
                                <Route  path="*" component={FirstTimeUser}/>
                            </Switch>

                            :

                            <Switch>
                                <Route exact path="/" component={DefaultDashboard}/> 
                                <Route exact path="/support" component={Support}/>
                                <Route exact path="/mybusiness" component={Mybusiness}/>
                                <Route exact path="/payments" component={Payments}/>
                                <Route exact path="/Account" component={Account}/> 
                                <Route exact path="/emojiexperience" component={Emojiexperience}/>
                                <Route exact path="/negativefeedback" component={Negativefeedback}/>
                                <Route exact path="/catalog" component={Catalog}/>
                                <Route  path="*" component={DefaultDashboard}/>
                            </Switch>


                        }
                       
                        
                       
                    </div>
                </div>

                <div className="copyright">
                    <span>{`${lang('copyright')} © ${process.env.REACT_APP_SITE_TITLE} ${new Date().getFullYear()}`}</span>
                </div>

                <Button 
                    className={"scroll-to-top rounded"} 
                    style={{display: scrollToTop}} 
                    onClick={() => scrollTop()}>
                        <FontAwesomeIcon icon={faAngleUp}  
                            color="white" 
                            className="mb-3" 
                            size="2x"/>
                </Button>

                <Modal isOpen={modal.show} toggle={() => setModal({show: false})}>
                    <ModalHeader toggle={() => setModal({show: false})}>
                        <h4 className="font-weight-bold">{modal.title}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: modal.body}}/>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: modal.footer}}/>

                        {signOut === true ? 
                            <Button color="info" onClick={() => logOut() }>
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>
                                {lang('sign_out')}
                            </Button> 
                        : ""}

                        <Button color="secondary" onClick={() => setModal({show: false})}>{lang('close')}</Button>
                    </ModalFooter>
                </Modal>
              
            </div>
        </div>
    )
}

export default Dashboard;