import React, {useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import {Button, Input, FormGroup, FormFeedback} from 'reactstrap';
import {userInfoContext, LoadingContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';


function Account()
{
    //Global states
    const [, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);
    const [userInfo] = useContext(userInfoContext);
    const [errors, setErrors] = useState({});


   async function UpdatePassword(post)
    {
        post.preventDefault();
        
        setLoading(true);

        let params = {
            email: userInfo.details.email,
            currentPassword: post.target.currentPassword.value,
            newpassword: post.target.newpassword.value,
            confirmNewPassword: post.target.confirmNewPassword.value
        }

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/updatepassword`, 
                params
            );

            setAlert({
                show: true,
                position: 'topRightAlert',
                message: lang('the_password_has_been_updated'),
                color: 'success'
            });
        }
        catch(error)
        {
            setErrors(error);
        }
        finally
        {
            setLoading(false);
        }
    }



    return (

        <div className="headerSpace card shadow mt-5 min-vh-100">

            <div className="titleBg card-header">
                <h2>{lang('account_settings_a')}</h2>
            </div>

            <div className="card-body">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <form onSubmit={UpdatePassword} noValidate className="col bg-light p-4 rounded m-3">
                            <h2 className="text-center font-weight-bold">{lang('general_info')}</h2>
                            <p><b>{lang('username')}:</b> {userInfo.details.username}</p>
                            <p><b>{lang('email')}:</b> {userInfo.details.email}</p>                            
                            
                            <hr className="my-0 mt-3"/>

                            <FormGroup>
                                <label className="font-weight-bold mt-2">{lang('current_password')}</label>
                                <Input type="password" name="currentPassword" className="form-control" placeholder={lang('current_password')} invalid={errors.currentPassword ? true : false}/>
                                <FormFeedback invalid={errors.currentPassword ? 'true' : ''}>{errors.currentPassword}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <label className="font-weight-bold mt-2">{lang('new_password')}</label>
                                <Input type="password" name="newpassword" className="form-control" placeholder={lang('new_password')} invalid={errors.newpassword ? true : false}/>
                                <FormFeedback invalid={errors.newpassword ? 'true' : ''}>{errors.newpassword}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <label className="font-weight-bold mt-2">{lang('confirm_new_password')}</label>
                                <Input type="password" name="confirmNewPassword" className="form-control" placeholder={lang('confirm_new_password')} invalid={errors.confirmNewPassword ? true : false}/>
                                <FormFeedback invalid={errors.confirmNewPassword ? 'true' : ''}>{errors.confirmNewPassword}</FormFeedback>
                            </FormGroup>

                            <div className="text-center">
                                <Button type="submit" color="info" size="lg" className="mt-5" ><FontAwesomeIcon icon={faSync}/> {lang('update_account_psw')}</Button>
                            </div>
                        </form>
                    </div>
                
                </div>
                
            </div>
        </div>
    );
    
}



export default Account;