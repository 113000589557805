import {EZFETCH} from '../../resources/modules/fetch';

async function isUserLogged()
{
    //check if logged in
    try
    {
        let currentUser = await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/user/info`);

        return {
            isLogged: true,
            details: currentUser.userInfo
        }
    }
    catch (errors)
    {
        return {
            isLogged: false,
            details: {}
        }
    }
}

export {isUserLogged};