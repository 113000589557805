import React, {useContext, useEffect} from 'react';
import {Alert } from 'reactstrap';
import {Switch, Route} from "react-router-dom";
import {LoadingContext,  AlertContext} from '../../store';
import {setLanguageDictionary, lang, currentLanguage} from '../../resources/languages';
import {LOADING} from '../parts/loading';
import '../../resources/css/app.css';



//components
import Login from './Login';
import Register from './Register';
import Resetpsw from './Resetpsw';
import EmailVerification from './EmailVerification';
import Experience from './experience';


function Public()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [showAlert, setAlert] = useContext(AlertContext);

    useEffect(() => {

        let alertTimeout = setTimeout(() => setAlert({show: false}), 8000);
          
        return () => {
            clearTimeout(alertTimeout);

        }

    },[showAlert]);


 


    const updateLocale = async (locale) => 
    {
        setLoading(true);

        await setLanguageDictionary(locale);

        setLoading(false);
    }


    return (
        <div id="wrapper" className="min-vh-100">
    
            {isLoading ? <LOADING theme="FULL_SCREEN"/> : ''}

            <Alert
                className={showAlert.position + " globalAlert"}
                isOpen={showAlert.show? true : false}
                color={showAlert.color}
                transition={{timeout: 150}}
                toggle={() => setAlert({show: false})}>

                <p>{showAlert.message}</p>
            </Alert>

            {window.location.pathname === "/experience" ? <Experience/> : 

                <div className="loginbg">
                    <div className="d-flex flex-row-reverse">
                        <div className="mr-5 mt-3">
                            <select
                                className="form-control"
                                value={lang('locale')}
                                onChange={(e) => updateLocale(e.target.value)}>

                                <option value="en">English</option>
                                <option value="es">Español</option>
                            </select>
                        </div>
                    </div>

                    <div className="container">
                        <div className="loginpage">

                            <Switch>
                                <Route exact path="/" component={Login}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/register" component={Register}/>
                                <Route exact path="/resetpsw" component={Resetpsw}/>
                                <Route exact path="/emailverification" component={EmailVerification}/>
                                <Route  path="*" component={Login}/>
                            </Switch>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Public;