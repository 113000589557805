import React, { useContext, useState, useEffect} from 'react';
import Logo from "../../resources/images/logo.svg";
import {lang} from '../../resources/languages';
import {LoadingContext, AlertContext} from '../../store';
import {EZFETCH} from '../../resources/modules/fetch';

import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faRedo } from '@fortawesome/free-solid-svg-icons';

function EmailVerification()
{
    //global
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    //local states
    const [errors, setErrors] = useState({}); //init as empty object
    const [userEmail, setUserEmail] = useState();
    const history = useHistory();
  
    useEffect(()=> {

        //Param message
        if(history.location.message !== undefined)
        {
            setAlert({
                position: 'topRightAlert',
                message: history.location.message,
                color: 'success', 
                show: true                
                }
            );
        };

        if(localStorage.getItem('userEmail') !== null)
        {
            setUserEmail(localStorage.getItem('userEmail'));
        }
        else
        {
            history.push({
                pathname: '/login',
                message:  lang('your_account_has_been_activated')
            });
        }

    }, [history.location.message]);


    async function verifyAccount(post)
    {
        post.preventDefault();

        if(isLoading)  return;

        setLoading(true);

        let postParams = {
            email: userEmail,
            token: post.target.token.value,
        };

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/validateToken`, 
                postParams
            );

            localStorage.removeItem('userEmail');//no needed anymore

            history.push({
                pathname: '/login',
                message:  lang('your_account_has_been_activated')
            });
        }
        catch (errors)
        {
            console.log(errors)

            setErrors(errors);
        }
        finally 
        {
            setLoading(false);
        }
    }


    async function sendNewToken() 
    {
        if(isLoading) return;

        setLoading(true);

        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/sendToken`, 
                { email: userEmail }
            );

            if (response === 'email_token_sent')
            {
                setAlert({
                    position: 'topRightAlert',
                    message: lang('a_token_has_been_sent'), //translator key
                    color: 'success', 
                    show: true                
                    }
                );
            }
        }
        catch (errors)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('something_went_wrong'), //translator key
                color: 'danger', 
                show: true                
                }
            );
        }
        finally 
        {
            setLoading(false);
        }
    }


    return (
        
        <form onSubmit={verifyAccount} noValidate>
             <a href="https://opinanow.com">
                 <img 
                src={Logo} 
                alt="" 
                className="img-fluid mb-3 align-self-center"/>
            </a>

            <h4 className="text-center text-white m-3">
                {lang('email_verification')}
            </h4>
            <p className="text-center text-white"> 
                {lang('a_verification_token_was_sent')}: 
                <span className="ml-1">{userEmail}</span>
            </p>

            <div className="floatingLabel">
                    <input 
                        type="number"
                        name="token" 
                        placeholder={lang('enter_token')}  
                        className={`${errors.token ? 'is-invalid' : ''} form-control`} />
                    <label for="token">{lang('enter_token')}</label>
                    <div {...errors.token ? 'invalid' : ''} className="invalid-feedback">{errors.token}</div>
                </div>

            <div className="text-center d-flex flex-column">
                <button type="submit" className="btn btn-info ">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2"/>
                    {lang('verify_email')}
                </button>

                <button className="btn btn-outline btn-info mt-3" onClick={() => sendNewToken()}>
                    <FontAwesomeIcon icon={faRedo} className="mr-2"/>
                    {lang('resend_token')}  
                </button>
            </div>
        </form>
    )
    
}

export default EmailVerification;