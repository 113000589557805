import React, {useState, useContext, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faCheck, faArrowAltCircleRight, faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons';

import {lang} from '../../resources/languages';
import MyBusinessIcon from '../../resources/images/my_business_icon.png';
import GoogleBiz from '../../resources/images/googlebiz.png';
import {LoadingContext, AlertContext, userInfoContext} from '../../store';
import '../../resources/css/dashboard.css';
import {useHistory} from 'react-router-dom';

import StripeSubscription from '../../resources/modules/stripe';

import {isUserLogged} from '../functions/user';

import {
    dentist, 
    lazer_hair_removal, 
    japanese_chef, 
    normal_chef, 
    builder, 
    pizza, 
    police, 
    professor, 
    hairdressing,
    designer, 
    doctor, 
    photographer, 
    ice_cream_maker, 
    computer, 
    engineer, 
    massage, 
    hairdresser, 
    painter} from '../../resources/images/emoji/png/';

import { Collapse} from 'reactstrap';

import { EZFETCH } from '../../resources/modules/fetch';




function FirstTimeUser()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);
    const [userInfo, setUserInfo] = useContext(userInfoContext);

    //local States
    const [step, setStep] = useState(1);
    const [placeData, setPlaceData] = useState({pladeID: null, name: null, address: null});
    const [emojiName, setEmojiName] = useState(null);
    const [category, setCategory] = useState(null);
    const [placePhotoUrl, setPlacePhotoUrl] = useState(); 
    const [placeAPIData, setPlaceAPIData] = useState({"candidates" :[{"formatted_address": null, "name": null, "place_id": null}], status: ""}); //default value
    const [googlePhoto, SetGooglePhoto] = useState(null);
  
     const history = useHistory();

     useEffect(async () => {

        console.log(history.location.pathname);

      
        if(history.location.pathname === "/canceled")
        {
            setAlert({
                show: true,
                position: 'topRightAlert',
                message: lang('the_payment_process_has_been'), 
                color: 'danger'
            });
        }

        if(history.location.pathname === "/success")
        {
            setStep(4);

            //send confirmation to server and update user info
            await paymentHandler(); 
        }


    },[history.location.pathname]);


    useEffect(() => {

        if(step === 4)
        {
            if(!placeData.placeID) return;
            sessionStorage.setItem('placeID', placeData.placeID);
            sessionStorage.setItem('category', category);
            sessionStorage.setItem('emojiName', emojiName);
        }
 
     },[step]);

    async function paymentHandler()
    {
        setLoading(true);

        //if(isLoading) return;

        //parse url params
        const searchParam = history.location.search; 
        const sessionParam = new URLSearchParams(searchParam);
        const sessionId = sessionParam.get('session_id'); 

        let params = {
            placeID: sessionStorage.getItem('placeID'),
            category: sessionStorage.getItem('category'),
            emojiName: sessionStorage.getItem('emojiName'),
            sessionId: sessionId
        }

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/payment/handle`,  
                params
            );

            window.location.href = `?message=${lang('your_payment_has_been_received')}`;
        }
        catch(error)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('an_error_has_ocurrend_b'), 
                color: 'danger', 
                show: true                
            });
        }
        finally
        {
            setLoading(false);
        }

    }


     async function findPlace(post)
     {
        post.preventDefault();

        if(!post.target.search.value) return;
 
        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/google/autocomplete`, 
                {'search': post.target.search.value}
            );

            setPlaceAPIData(response);
        }
        catch(serverErrors)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('an_error_has_ocurred_a'), 
                color: 'danger', 
                show: true                
            });
        }
     }

     
     async function completeTrialRegistration()
     {
        setLoading(true);

        if(isLoading) return;

        let params = {
            placeID: placeData.placeID,
            category: category,
            emojiName: emojiName
        }

        try
        {
            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/payment/trial`,  
                params
            );
          
            window.location.href = `?message=${lang('your_account_has_been_activated')}`;
        }
        catch(error)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('an_error_has_ocurrend_b'), 
                color: 'danger', 
                show: true                
            });
        }
        finally
        {
            setLoading(false);
        }
     }


     const stepTwoValidations = () =>{

        if(emojiName == null || category == null)
        {
            setAlert({
                position: 'topRightAlert',
                message: !emojiName ? lang('you_must_select_an_emoji') : lang('you_must_select_a_category'), 
                color: 'danger', 
                show: true                
                }
            );

            return;
        }

        setStep(3);
     }

     
     

    return (
        <div className="container-fluid">
            <div className="headerSpace card shadow mt-5">
                <div className="card-body min-vh-100">     

                    <ul className="steps">
                        <li className={`step step-${step === 1 ? 'active' : step > 1 ? 'success' : ''}`}>
                            <div className="step-content">
                                <span className="step-circle">1</span>
                                <span className="step-text">{lang('step_one')}</span>
                            </div>
                        </li>
                        <li className={`step step-${step === 2 ? 'active' : step > 2 ? 'success' : ''}`}>
                            <div className="step-content">
                                <span className="step-circle">2</span>
                                <span className="step-text">{lang('step_two')}</span>
                            </div>
                        </li>
                        <li className={`step step-${step === 3 ? 'active' : step > 3 ? 'success' : ''}`}>
                            <div className="step-content">
                                <span className="step-circle">3</span>
                                <span className="step-text">{lang('step_three')}</span>
                            </div>
                        </li>
                        <li className={`step step-${step === 4 ? 'active' : step > 4 ? 'success' : ''}`}>
                            <div className="step-content">
                                <span className="step-circle">4</span>
                                <span className="step-text">{lang('step_four')}</span>
                            </div>
                        </li>
                    </ul>
                            
                    <Collapse className="mx-auto mt-3" isOpen={step === 1 ? true : false} style={{maxWidth: '600px'}}>
                        
                        <h2 className="font-weight-bold text-center">
                            {lang('find_and_select_your_business')}
                        </h2>

                        <img 
                            src={!!googlePhoto ? `${process.env.REACT_APP_SERVER_URL}/google/photo?reference=${googlePhoto}` : GoogleBiz} 
                            className="mx-auto d-block mt-5 mb-5 rounded" 
                            alt=""/>
                                            
                        <form onSubmit={findPlace} noValidate>
                            <div className="input-group input-group-lg">
            
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="search" 
                                    placeholder={lang('please_enter_your_business_name')} 
                                    onChange={(e)=> e.target.value === '' ? setPlaceData({pladeID: null, name: null, address: null})  : ''}/>
                                
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-info">
                                        <FontAwesomeIcon  icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                        </form>
            
                        <Collapse 
                            className="container-fluid scrollEffect shadow p-3 " 
                            isOpen={placeAPIData.status === "OK"  && placeData.name === null ? true : false}>
                        {
                            placeAPIData.candidates.map((item, key) =>  (
                                    
                                <div key={key} 
                                    className="d-flex border-bottom mt-2"
                                    onClick={()=> {setPlaceData({
                                        placeID: item.place_id, 
                                        name: item.name, 
                                        address: item.formatted_address}); 

                                        SetGooglePhoto(item.photos[0].photo_reference);}}>                       
                                
                                <img 
                                    src={MyBusinessIcon}
                                    className="rounded-circle"
                                    style={{height: '50px'}} 
                                    alt=""/>
                                
                                    <div className="ml-3">
                                        <h6 className="font-weight-bold">{item.name}</h6>
                                        <p>{item.formatted_address}</p>
                                    </div>
                                </div>                    
                            ))
                        }
                        </Collapse>            
                        
                        <Collapse
                            className="mt-3" 
                            isOpen={placeData.name ? true : false}>

                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('business_name')}: 
                                </span>
                                {placeData.name}
                            </p>
                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('address')}: 
                                </span>
                                {placeData.address}
                            </p>

                            <p>
                                {lang('click_next_if')}
                            </p>
            
                            <button 
                                className="btn btn-info"  
                                onClick={()=> setStep(2)}>
                                {lang('next')}  
                                <FontAwesomeIcon className="ml-2" icon={faArrowAltCircleRight} />
                            </button>
                        </Collapse>
                    
                    </Collapse>

                    <Collapse isOpen={step === 2 ? true : false} className="stepContentContainer">
                        <div className="row border-bottom pb-5">
                            <div className="col-sm-6">
                                <p className="mt-5 font-weight-bold">
                                    {lang('select_the_emoji_for_your_biz')}
                                </p>
            
                                <div className="emojiselector">
                                    <img 
                                        src={normal_chef} 
                                        className={emojiName === "normal_chef" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('normal_chef')} 
                                        alt=""/>                
                                
                                    <img 
                                        src={japanese_chef} 
                                        className={emojiName === "japanese_chef" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('japanese_chef')} 
                                        alt=""/>              
                                
                                    <img 
                                        src={builder} 
                                        className={emojiName === "builder" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('builder')} 
                                        alt=""/>                
                                
                                    <img 
                                        src={dentist} 
                                        className={emojiName === "dentist" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('dentist')} 
                                        alt=""/>
                                
                                    <img 
                                        src={lazer_hair_removal} 
                                        className={emojiName === "lazer_hair_removal" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('lazer_hair_removal')} 
                                        alt=""/>
                                
                                    <img 
                                        src={designer} 
                                        className={emojiName === "designer" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('designer')}
                                        alt=""/>
                                
                                    <img 
                                        src={doctor} 
                                        className={emojiName === "doctor" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('doctor')}
                                        alt=""/>
                                
                                    <img 
                                        src={photographer} 
                                        className={emojiName === "photographer" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('photographer')}
                                        alt=""/>
                                
                                    <img 
                                        src={ice_cream_maker} 
                                        className={emojiName === "ice_cream_maker" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('ice_cream_maker')}
                                        alt=""/>
                                
                                    <img 
                                        src={computer} 
                                        className={emojiName === "computer" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('computer')}
                                        alt=""/>
                                
                                    <img 
                                        src={engineer} 
                                        className={emojiName === "engineer" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('engineer')}
                                        alt=""/>
                                
                                    <img 
                                        src={massage} 
                                        className={emojiName === "massage" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('massage')}
                                        alt=""/>
                                
                                    <img 
                                        src={hairdresser} 
                                        className={emojiName === "hairdresser" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('hairdresser')}
                                        alt=""/>
                                
                                    <img 
                                        src={painter} 
                                        className={emojiName === "painter" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('painter')}
                                        alt=""/>

                                    <img 
                                        src={pizza} 
                                        className={emojiName === "pizza" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('pizza')}
                                        alt=""/>
                                
                                    <img 
                                        src={police} 
                                        className={emojiName === "police" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('police')}
                                        alt=""/>
                                
                                    <img 
                                        src={professor}
                                        className={emojiName === "professor" ? 'emojiBackground' : ''} 
                                        onClick={() => setEmojiName('professor')}
                                        alt=""/>
                                
                                    <img 
                                        src={hairdressing} 
                                        className={emojiName === "hairdressing" ? 'emojiBackground' : ''}
                                        onClick={() => setEmojiName('hairdressing')}
                                        alt=""/>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <p className="font-weight-bold mt-5">
                                    {lang('what_type_of_business')}
                                </p>
            
                                <select size="12"  className="form-control" onClick={(e) => setCategory(e.target.value)}>
                                    <option value="restaurant" >{lang('restaurant')}</option>
                                    <option value="mecanic">{lang('mecanic')}</option>
                                    <option value="reforms">{lang('reforms')}</option>
                                    <option value="construction">{lang('construction')}</option>
                                    <option value="decoration">{lang('decoration')}</option>
                                    <option value="sport">{lang('sport')}</option>
                                    <option value="barber">{lang('barber')}</option>
                                    <option value="icecrem">{lang('icecrem')}</option>
                                    <option value="dentist">{lang('dentist')}</option>
                                    <option value="design">{lang('design')}</option>
                                    <option value="medicine">{lang('medicine')}</option>
                                    <option value="photography">{lang('photography')}</option>
                                    <option value="computers">{lang('computers')}</option>
                                    <option value="massage">{lang('massage')}</option>
                                    <option value="engineering">{lang('engineering')}</option>
                                    <option value="painting">{lang('painting')}</option>
                                    <option value="security">{lang('security')}</option>
                                    <option value="professor">{lang('professor')}</option>
                                </select>
                            </div>
                        </div>

                        <div className="text-center  mt-3">
                            <button 
                                className="btn btn-info mr-3" 
                                onClick={()=> setStep(1)}>
                                <FontAwesomeIcon  className="mr-1" icon={faArrowAltCircleLeft}/>
                                {lang('back')}
                            </button>
                            <button 
                                className="btn btn-info" 
                                onClick={()=> stepTwoValidations()} >
                                {lang('next')} 
                                <FontAwesomeIcon className="ml-1" icon={faArrowAltCircleRight} />
                            </button>
                        </div>
                    </Collapse>

                    <Collapse isOpen={step === 3 ? true : false} className="stepContentContainer">
        
                        <h3 className="font-weight-bold mt-3 text-center">
                            {lang('lets_confirm_your_information')}
                        </h3>
                        
                        <img  
                            src={placePhotoUrl} 
                            className="mx-auto d-block mt-5" 
                            alt=""/>
        
                        <div className="mt-5 border-bottom">

                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('business_name')}: 
                                </span>
                                {placeData.name}
                            </p>
                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('address')}: 
                                </span>
                                {placeData.address}
                            </p>
                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('category')}: 
                                </span>
                                {category}
                            </p>
                            <p>
                                <span className="font-weight-bold mr-1">
                                    {lang('positive_experience_emoji')}: 
                                </span>

                                <img 
                                    src={emojiName !== null ? require(`../../resources/images/emoji/gif/${emojiName}.gif`) : ""} 
                                    className="mr-3" 
                                    alt=""/>
                            </p>                   
                        </div>

                        <div className="text-center  mt-3">
                            <button 
                                className="btn btn-info mr-3" 
                                onClick={()=> setStep(2)}>
                                <FontAwesomeIcon  className="mr-1" icon={faArrowAltCircleLeft}/>
                                {lang('back')}
                            </button>
                            <button 
                                className="btn btn-info" 
                                onClick={()=> setStep(4)} >
                                {lang('next')} 
                                <FontAwesomeIcon className="ml-1" icon={faArrowAltCircleRight} />
                            </button>
                        </div>
                       
                    </Collapse>

                    <Collapse isOpen={step === 4 ? true : false}>
                        <section className="pricing py-5">
                            <div className="container">

                                <h2 className="font-weight-bold text-center">{lang('select_a_plan')}</h2>

                                <div className="row mt-5">
                                    <div className="col-lg-4">
                                        <div className="card mb-5 mb-lg-0">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">{lang('try_it_for_30_days')}</h5>
                                                <h6 className="card-price text-center">{lang('free')}</h6>
                                                <hr/>
                                                <ul className="fa-ul">
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('emoji_qr_experience')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('qr_menu_plus_recommendations')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('filter_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_rating_protection')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_business_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('facebook_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('respond_to_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('control_panel_and_more')}</li>
                                                </ul>

                                                <div className="text-center">
                                                    <button 
                                                        className="btn btn-info btn-lg" 
                                                        onClick={()=> completeTrialRegistration()}>
                                                        {lang('subscribe')}
                                                    </button>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="card mb-5 mb-lg-0">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">{lang('montly_plan')}</h5>
                                                <p className="text-center text-muted">({lang('most_popular')})</p>
                                                <h6 className="card-price text-center">49€<span className="period">/{lang('month')}</span></h6>
                                                <p className="text-center">{lang('tax_included')}</p>
                                                <hr/>
                                                <ul className="fa-ul">
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('emoji_qr_experience')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('qr_menu_plus_recommendations')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('filter_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_rating_protection')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_business_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('facebook_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('respond_to_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('control_panel_and_more')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('custom_qr_advertising_design')}</li>

                                                </ul>
                                                
                                                
                                                <div className="text-center">
                                                    <StripeSubscription 
                                                        priceID="price_1HjomhAtG375sOjD1O0DaPPM" 
                                                        className="btn btn-info btn-lg"
                                                        email={userInfo.details.email}
                                                        text={lang('subscribe')}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="card">
                                                <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">{lang('anual_plan')}</h5>
                                                <p className="text-center text-muted">({lang('two_months_free')})</p>
                                                <h6 className="card-price text-center">490€<span className="period">/{lang('anual')}</span></h6>
                                                <p className="text-center">{lang('tax_included')}</p>
                                                <hr/>
                                                <ul className="fa-ul">
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('emoji_qr_experience')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('qr_menu_plus_recommendations')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('filter_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_rating_protection')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('google_business_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('facebook_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('respond_to_negative_reviews')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('control_panel_and_more')}</li>
                                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} className="text-success"/></span>{lang('custom_qr_advertising_design')}</li>

                                                </ul>
                                                
                                                <div className="text-center">
                                                    <StripeSubscription 
                                                        priceID="price_1Hjom3AtG375sOjDnagijEHR" 
                                                        className="btn btn-info btn-lg"
                                                        email={userInfo.details.email}
                                                        text={lang('subscribe')}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

export default FirstTimeUser;