import React, { useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {LoadingContext,  AlertContext} from '../../store';

import {getRecaptchaV3Token} from '../../resources/modules/recaptcha';
import {EZFETCH} from '../../resources/modules/fetch';
import {lang} from '../../resources/languages';
import Logo from "../../resources/images/logo.svg";

import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Register ()
{
    //Global States
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    //Local states
    const [errors, setErrors] = useState({}); //init as empty object
    const [inputType, setInputType] = useState({password: 'password', confirmPassword: 'password'});
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [modal, setModal] = useState({show: false});
    
    //Browser history
    const history = useHistory();


    async function handleSignup(post)
    {
        post.persist();
        post.preventDefault();

        if(isLoading) return;     

        if(modal.show === true) return;//prevents autosubmit on button click

        if(!acceptTerms)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('you_must_agree_to_the_terms'), 
                color: 'danger', 
                show: true                
                }
            );

            return;
        }

        let postParams = {
            fname : post.target.fname.value,
            lname : post.target.lname.value,
            username : post.target.username.value,
            email : post.target.email.value,
            password : post.target.password.value,
            confirmPassword : post.target.confirmPassword.value,
            recaptchaResponse: await getRecaptchaV3Token()
        };

        localStorage.setItem('userEmail', post.target.email.value);//to validate it later

        setLoading(true);
       
        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/register`, 
                postParams
            );

            if(response === 'email_token_sent')
            {
                history.push({
                    pathname: '/emailverification', 
                    message:  lang('a_token_has_been_sent') 
                 });
            }
        }
        catch (error)
        {
            setErrors(error);
        }
        finally 
        {
            setLoading(false);
        }
    }

    return (
        <div>
            <form onSubmit={handleSignup} noValidate>
            <a href="https://opinanow.com">
                <img 
                    src={Logo} 
                    alt="" 
                    className="img-fluid mb-3 align-self-center"/>
            </a>

                <h5 className="text-center text-white m-3">
                    {lang('register_title')}
                </h5>

                <div className="floatingLabel">
                    <input 
                        type="text"
                        name="fname" 
                        placeholder={lang('fname')}  
                        className={`${errors.fname ? 'is-invalid' : ''} form-control`} />
                    <label>{lang('fname')}</label>
                    <div {...errors.fname ? 'invalid' : ''} className="invalid-feedback">{errors.fname}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type="text"
                        name="lname" 
                        placeholder={lang('lname')}  
                        className={`${errors.lname ? 'is-invalid' : ''} form-control`} />
                    <label>{lang('lname')}</label>
                    <div {...errors.lname ? 'invalid' : ''} className="invalid-feedback">{errors.lname}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type="text"
                        name="username" 
                        placeholder={lang('username')}  
                        className={`${errors.username ? 'is-invalid' : ''} form-control`} />
                    <label>{lang('username')}</label>
                    <div {...errors.username ? 'invalid' : ''} className="invalid-feedback">{errors.username}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type="text"
                        name="email" 
                        placeholder={lang('email')}  
                        className={`${errors.email ? 'is-invalid' : ''} form-control`} />
                    <label>{lang('email')}</label>
                    <div {...errors.email ? 'invalid' : ''} className="invalid-feedback">{errors.email}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type={inputType.password} 
                        name="password" 
                        placeholder={lang('password')}  
                        className={`${errors.password ? 'is-invalid' : ''} form-control  passwordInputFeedback`} />
                    <label>{lang('password')}</label>
                    
                    <span 
                        className="passwordInput"
                        onClick={() => inputType.password === 'password' ? 
                            setInputType({password: 'text', confirmPassword: inputType.confirmPassword}) : 
                            setInputType({password: 'password', confirmPassword: inputType.confirmPassword})}>  
                        {inputType.password === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </span>               

                    <div {...errors.password ? 'invalid' : ''} className="invalid-feedback">{errors.password}</div>
                </div>

                <div className="floatingLabel">
                    <input 
                        type={inputType.confirmPassword} 
                        name="confirmPassword" 
                        placeholder={lang('confirmPassword')}  
                        className={`${errors.confirmPassword ? 'is-invalid' : ''} form-control passwordInputFeedback`} />
                    <label>{lang('confirmPassword')}</label>
                    
                    <span 
                        className="passwordInput"
                        onClick={() => inputType.confirmPassword === 'password' ? 
                        setInputType({confirmPassword: 'text', password: inputType.password}) : 
                        setInputType({confirmPassword: 'password', password: inputType.password})}>  
                        {inputType.confirmPassword === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </span>               

                    <div {...errors.confirmPassword ? 'invalid' : ''} className="invalid-feedback">{errors.confirmPassword}</div>
                </div>

                <div className="form-group text-center ml-2">
                    <input 
                        name="accept" 
                        type="checkbox" 
                        onClick={()=> setAcceptTerms({acceptTerms: acceptTerms ? false : true})}/>

                    <span className="text-white ml-2">
                        {lang('I_have_read_and_agree_to_the')}

                        <button  
                            className="text-info  mr-1 ml-1 buttonToLink" 
                            onClick={()=> setModal({
                                show: true, 
                                title: lang('terms_and_conditions'), 
                                body: lang('terms')})}> 
                            {lang('terms_and_conditions').toLowerCase()} 
                        </button> 

                        {lang('and').toLowerCase()} 

                        <button
                            className="text-info ml-1 buttonToLink" 
                            onClick={()=> setModal({
                                show: true, 
                                title: lang('privacy_policy'), 
                                body: lang('privacy_p')})}> 
                            {lang('privacy_policy').toLowerCase()}
                        </button>
                    </span>
                </div>

                <div className="text-center mt-3 d-flex flex-column">

                    <button 
                        className="btn btn-info btn-lg" 
                        type="submit"> 
                        {lang('register')} 
                    </button>

                    <Link to="/login" className="mt-5 mb-2 text-white">{lang('login')}</Link>
                </div>            
            </form>

            <Modal
                style={{maxWidth : '1600px', width: '90%'}}
                isOpen={modal.show}
                toggle={() => setModal({show: false})}
                scrollable={true}>

                <ModalHeader toggle={() => setModal({show: false})}>
                    <div className="font-weight-bold">
                        {modal.title}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: modal.body}}/>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Register;