import React, {useContext, useState, useEffect} from 'react';
import {LoadingContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import '../../resources/css/emojiExperience.css';
import Negative from '../../resources/images/emoji/gif/negative.gif';
import classnames from 'classnames';
import Rating from '../../resources/modules/rating';
import LogoB from '../../resources/images/logo-black.svg';

import {
  dentist, 
  lazer_hair_removal, 
  japanese_chef, 
  normal_chef, 
  builder, 
  pizza, 
  police, 
  professor, 
  hairdressing,
  designer, 
  doctor, 
  photographer, 
  ice_cream_maker, 
  computer, 
  engineer, 
  massage, 
  hairdresser, 
  painter} from '../../resources/images/emoji/png/';

  import {  
    Input, 
    FormGroup, 
    CustomInput,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent
  } from 'reactstrap';

function Emojiexperience()
{
  //Global states
  const [isLoading, setLoading] = useContext(LoadingContext);
  const [, setAlert] = useContext(AlertContext);
  const [activeTab, setActiveTab] = useState('1');

  //Local States (añadido por enrique despues de useState)
     const [businessInfo, setBusinessInfo] = useState({
      "reviews" : [],
      "reviewsCount" : {"oneStar": 0, "twoStars" : 0, "threeStars" : 0, "fourStars" : 0, "fiveStars": 0},
      "rating" : 0,
      "user_ratings_total" : 0
  });

  //Local States
  const [bizHeader, setBizHeader] = useState();
  const  businessUrl = `https://app.opinanow.com/experience?business=${businessInfo.userID}`;

   //componentDidMount
   useEffect( () => {

    async function getBusinessInfo()
    {
      setLoading(true);

      try
      {
        let response = await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/business/businessinfo`);

        setBusinessInfo(response);
      }
      catch(serverErrors)
      {
        setAlert({
          show: true,
          position: 'topRightAlert',
          message: lang('something_went_wrong'),
          color: 'danger'
        });
      }
      finally
      {
        setLoading(false);
      }
    }

    getBusinessInfo();

 }, []);



  async function updateFacebookPage(post)
  {
    post.preventDefault();

    if(isLoading) return;

    if(!post.target.fbpage.value) return;

    setLoading(true);

    try
    {
      await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/business/updatefburl`,
        {fbpage: post.target.fbpage.value}
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('something_went_wrong'),
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
  }

  async function updateCategory(post)
  {
    post.preventDefault();

    if(isLoading) return;

    if(!post.target.category.value) return;

    setLoading(true);

    try
    {
      await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/business/updatecategory`,
        {category: post.target.category.value}
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('something_went_wrong'),
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
  }


  async function updateImage() 
  {
    if(isLoading) return;
    
    if (!bizHeader)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang("please_select_an_image"),
        color: 'danger'
      });
    } 

    // Create an object of formData 
    var formData = new FormData();
    
    // Update the formData object 
    formData.append('headerimg', bizHeader); 
       
    setLoading(true);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    try
    {

      let resp = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/system/uploadImage`,
        formData,
        config
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: serverErrors.response.data,
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
    
  };

  async function updateEmoji(emojiName)
  {
    setLoading(true);

    try
    {
      await EZFETCH.post(
        `${process.env.REACT_APP_SERVER_URL}/business/updateemoji`,
        {emojiName: emojiName}
      );

      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('update_success'),
        color: 'success'
      });
    }
    catch(serverErrors)
    {
      setAlert({
        show: true,
        position: 'topRightAlert',
        message: lang('something_went_wrong'),
        color: 'danger'
      });
    }
    finally
    {
      setLoading(false);
    }
  }

  function downloadQR()
  {
    var a = document.createElement("a");
    a.href = `${process.env.REACT_APP_SERVER_URL}/barcode/qr?text=${businessUrl}`;
    a.setAttribute("download", "qr code");
    a.click();
  }

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (

      <div className="container-fluid">
        <div className="headerSpace card shadow mt-5">
          <div className="titleBg card-header">
            <h1 className="font-weight-bold">
              {lang('emoji_experience')}
            </h1>
          </div>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${classnames({ active: activeTab === '1' })} customPointer` }
                onClick={() => { toggle('1'); }}>
                
                {lang('qr_code')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${classnames({ active: activeTab === '2' })} customPointer` }
                onClick={() => { toggle('2'); }}>
                
                {lang('business')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${classnames({ active: activeTab === '3' })} customPointer` }
                onClick={() => { toggle('3'); }}
              >
                Apple Maps
              </NavLink>
            </NavItem>

          {/**
            *  <NavItem>
              <NavLink
              className={`${classnames({ active: activeTab === '4' })} customPointer` }
                onClick={() => { toggle('4'); }}
              >
                Mamaguevo
              </NavLink>
            </NavItem>
            */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="colOpt col-sm-6 experienceTabs p-5">
                <div className="text-center">
                  <h2>
                    {lang('qr_code_of_user_experience')}
                  </h2>

                  <img src={`${process.env.REACT_APP_SERVER_URL}/barcode/qr?text=${businessUrl}`} alt="qr code"/><br/>

                  <button
                    onClick={()=> downloadQR()}
                    className="btn btn-info" >
                    {lang('download_qr_code')}
                  </button>

                </div>

                <div className="d-flex mt-4">
                  <FontAwesomeIcon className="text-info mr-1 mt-1" icon={faInfoCircle} />
                  <p>{lang('download_qr_info')}</p>
                </div>
              </div>
            </TabPane>
            
            <TabPane tabId="2">
              <div className="row experienceTabs p-5">
                <div className="colOpt col-sm-6">
                  <h2 className="text-center">
                    {lang('modify_your_experience_page')}
                  </h2>

                  <form className="mt-4" onSubmit={updateCategory} noValidate>
                    <div className="form-group">
                      <label className="font-weight-bold">{lang('update_category')}</label>
                      <div className="input-group">

                        <select name="category" className="form-control">
                          <option value="restaurant" >{lang('restaurant')}</option>
                          <option value="mecanic">{lang('mecanic')}</option>
                          <option value="reforms">{lang('reforms')}</option>
                          <option value="construction">{lang('construction')}</option>
                          <option value="decoration">{lang('decoration')}</option>
                          <option value="sport">{lang('sport')}</option>
                          <option value="barber">{lang('barber')}</option>
                          <option value="icecrem">{lang('icecrem')}</option>
                          <option value="dentist">{lang('dentist')}</option>
                          <option value="design">{lang('design')}</option>
                          <option value="medicine">{lang('medicine')}</option>
                          <option value="photography">{lang('photography')}</option>
                          <option value="computers">{lang('computers')}</option>
                          <option value="massage">{lang('massage')}</option>
                          <option value="engineering">{lang('engineering')}</option>
                          <option value="painting">{lang('painting')}</option>
                          <option value="security">{lang('security')}</option>
                          <option value="professor">{lang('professor')}</option>
                        </select> 
                        
                        <div className="input-group-prepend">
                          <button className="btn btn-info rounded-right" type="submit">{lang('update_category_a')}</button>
                        </div>
                        
                      </div>
                    </div>
                  </form>

                    <FormGroup>
                      <label className="font-weight-bold">{lang('upload_heading_image')}</label>
                      <div className="input-group">
                        
                        <CustomInput 
                          type="file" 
                          name="myImage" 
                          onChange={(e) => setBizHeader(e.target.files[0])} 
                          label={lang('select_image')}
                        />

                        <div className="input-group-prepend">
                        <button  className="btn btn-info rounded-right" onClick={() => updateImage()}>
                          {lang('upload_image')}
                        </button> 
                        </div>
                      </div>
                      
                    </FormGroup>
                

                  <div>
                    <label className="font-weight-bold">{lang('change_positive_emoji')}</label>

                    <div className="emojiselector">
                      <img src={normal_chef} onClick={() => updateEmoji('normal_chef')} alt=""/>                
                    
                      <img src={japanese_chef} onClick={() => updateEmoji('japanese_chef')} alt=""/>              
                    
                      <img src={builder} onClick={() => updateEmoji('builder')} alt=""/>                
                    
                      <img src={dentist} onClick={() => updateEmoji('dentist')} alt=""/>
                    
                      <img src={lazer_hair_removal} onClick={() => updateEmoji('chef')} alt=""/>
                    
                      <img src={designer} onClick={() => updateEmoji('designer')} alt=""/>
                    
                      <img src={doctor} onClick={() => updateEmoji('doctor')} alt=""/>
                    
                      <img src={photographer} onClick={() => updateEmoji('photographer')} alt=""/>
                    
                      <img src={ice_cream_maker} onClick={() => updateEmoji('ice_cream_maker')} alt=""/>
                    
                      <img src={computer} onClick={() => updateEmoji('computer')} alt=""/>
                    
                      <img src={engineer} onClick={() => updateEmoji('engineer')} alt=""/>
                    
                      <img src={massage} onClick={() => updateEmoji('massage')} alt=""/>
                    
                      <img src={hairdresser} onClick={() => updateEmoji('hairdresser')} alt=""/>
                    
                      <img src={painter} onClick={() => updateEmoji('painter')} alt=""/>
                    
                      <img src={pizza} onClick={() => updateEmoji('pizza')} alt=""/>
                    
                      <img src={police} onClick={() => updateEmoji('police')} alt=""/>
                    
                      <img src={professor} onClick={() => updateEmoji('professor')} alt=""/>
                    
                      <img src={hairdressing} onClick={() => updateEmoji('hairdressing')} alt=""/>
                    </div>
                  </div>

                  <div className="d-flex mt-4">
                    <FontAwesomeIcon className="text-info mr-1 mt-1" icon={faInfoCircle} />
                    <p>{lang('redesign_your_public_page')}<br/><br/> {lang('you_can_also_update_the_emoji')}</p>
                  </div>
                </div>
                
                
                <div className="colOpt col-sm-6 phonePreview">
                  <h2 className="text-center">
                    {lang('preview')}
                  </h2>

                <div className="mobileIphone iphonexm">

                <iframe className="iframePhone" src={`https://app.opinanow.com/experience?business=${businessInfo.userID}`} name="myiFrame" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="0px" height="650" width="100%" allowfullscreen="">

                </iframe>
                </div>

                  <div className="previewMobile d-flex justify-content-center">
                    <button 
                      className="btn btn-info"
                      onClick={()=> window.location.reload()}>{lang('refresh')}</button>
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane tabId="3">
              <div className="colOpt col-sm-6 experienceTabs p-5">           
                <h2 className="text-center">
                  {lang('add_fb_fanpage')}
                </h2>

                <form className="mt-4" onSubmit={updateFacebookPage} noValidate>
                  <div className="form-group">
                      <label className="font-weight-bold">{lang('add_fb_link')}</label>
                      <div className="input-group">
                        <Input 
                            type="text"
                            name="fbpage" 
                            defaultValue={businessInfo.facebookURL}
                            placeholder={`${lang('ex')} http://maps.apple.com/?q=${lang('Apple_URL_Business')}`}/>
                        
                        <div className="input-group-prepend">
                          <button className="btn btn-info rounded-right" type="submit">{lang('add_fanpage')}</button>
                        </div>
                      </div>
                  </div>
                </form>            

                <div className="d-flex mt-4">
                  <FontAwesomeIcon className="text-info mr-1 mt-1" icon={faInfoCircle} />
                  <p>{lang('iphones_are_optional_in_google')}<br/><br/> {lang('add_your_fb_fanpage_here')}<br/><br/> <b>{lang('add_your_business_to_apple')}</b></p>
                </div>
              </div>
            </TabPane>

          {/**
            * 
            *<TabPane tabId="4">
            *     Este es tab 4 aki agrega lo k tu kieras 
            </TabPane>
            */}
          
          </TabContent>
       </div>
     </div>
  )
}

export default Emojiexperience;