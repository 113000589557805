import React, {useState, useContext, useEffect} from 'react';
import {LoadingContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import {useHistory} from "react-router-dom";
import {Modal, ModalHeader, ModalBody,  Input, FormGroup, FormFeedback, TabContent, TabPane} from 'reactstrap';
import Negative from '../../resources/images/emoji/gif/negative.gif';
import '../../resources/css/experience.css';
//import GoogleReviewsLogo from '../../resources/images/footer-platform.png';
import MenuIcon from '../../resources/images/menu.png'
import GoogleReviews from '../../resources/images/googleReviews.png';
import FacebookReviews from '../../resources/images/Apple-maps.png';
import Rating from '../../resources/modules/rating';
//resources
import LogoB from '../../resources/images/logo-black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrinHearts, faStarHalfAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';





function Experience()
{

    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    const [recommendation, setRecommendation] = useState({});

    //Local States (añadido por enrique despues de useState)
    const [businessInfo, setBusinessInfo] = useState({
        "reviews" : [],
        "reviewsCount" : {"oneStar": 0, "twoStars" : 0, "threeStars" : 0, "fourStars" : 0, "fiveStars": 0},
        "rating" : 0,
        "user_ratings_total" : 0
    });
    const [modal, setModal] = useState({show: false});
    const [errors, setErrors] = useState({});
   
    //Browser history
    const history = useHistory();

    //Constants
    const positiveReviewURL = `https://search.google.com/local/writereview?placeid=${businessInfo.placeID}`;
    const _iOSDevice = navigator.platform.match(/iPhone|iPod|iPad/);//detect if it is iphone or ipod
    const businessID = new URLSearchParams(history.location.search).get('business'); //get param business


    const [activeTab, setActiveTab] = useState('1');


    //componentDidMount
    useEffect( () => {

        //if business param is undefined we will redirect to google by default
        if(businessID === undefined)
        {
            window.location.replace('https://google.com');
        }

        const getBusinessInfo = async () =>
        {
            setLoading(true);

            try
            {
                let response = await EZFETCH.post(
                    `${process.env.REACT_APP_SERVER_URL}/business/businessInfoPublic`,
                    {businessID: businessID}
                );

                setBusinessInfo(response);
            }
            catch(serverErrors)
            {
                //if there is an error fetching info we will redirect to google to ensure we receive a good feedback anyways
               window.location.replace('https://google.com');
            }
            finally
            {
                setLoading(false);
            }
        }

        recommendations();

        
        getBusinessInfo();

        //post a nulled click
        postClick({negative: 0, positive: 0, facebook: 0, nulled: 1, device: _iOSDevice, businessID: businessID});

    }, []);
    

    const postClick = (action) =>
    {
        EZFETCH.post(
            `${process.env.REACT_APP_SERVER_URL}/system/clickcount`, 
            action
        );
    }


    const clickCount = (wheretogo) =>
    {
       // setModal({show: true, title: lang('where_would_you_like_to_leave'), mode: 2});

        if(wheretogo === 'negative')
        {
            //counter update
            postClick({negative: 1, positive: 0, facebook: 0, nulled: 0, device: _iOSDevice, businessID: businessID});
            
            //show modal
            setModal({show: true, title: lang('help_us_improve_a_n'), mode: 1});
        }

        else if(wheretogo === 'positive')
        {
            //counter update
            postClick({negative: 0, positive: 1, facebook: 0, nulled: 0, device: _iOSDevice, businessID: businessID});

            //redirect to google
            window.location.replace(positiveReviewURL);
        }
        else if(wheretogo === 'showOption')
        {
            //show modal
            setModal({show: true, title: lang('where_would_you_like_to_leave'), mode: 2});
        }

        else if(wheretogo === 'facebook')
        {
            //counter update
            postClick({negative: 0, positive: 0, facebook: 1, nulled: 0, device: _iOSDevice, businessID: businessID});

            window.location.replace(`${businessInfo.facebookURL}`);
        }
    }

    const handleNegativeReview = async (post) => 
    {
        post.preventDefault();
        
        if(isLoading) return;

        setLoading(true);

        let postParams = {
            name : post.target.name.value,
            email : post.target.email.value,
            review : post.target.review.value,
            businessID : businessID
        };

        try
        {

            if(!postParams.name)
            {
                setErrors({name: lang('name is requried')});

                return;
            }

            setModal({show: false});

            await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/system/review`, 
                postParams
            );

            setAlert({
                position: 'topRightAlert',
                message: lang('thank_you_for_your_review'),
                color: 'success', 
                show: true                
            });
        }
        catch(errors)
        {
            setAlert({
                position: 'topRightAlert',
                message: lang('something_went_wrong'), 
                color: 'danger', 
                show: true                
            });
        }
        finally
        {
            setLoading(false);
        }
    }

    const recommendations = async () =>
    {
        setLoading(true);

        try
        {
            let response = await EZFETCH.post(`${process.env.REACT_APP_SERVER_URL}/system/getBusinessRecommendation`, {businessId: businessID});

            setRecommendation(response);
        }
        catch(serverErrors)
        {

        }
        finally
        {
            setLoading(false);
        }
    }


    return (

        <div className="contentWrapper">

            <div 
                className="experienceHeader" 
                style={{
                    backgroundImage: !!businessInfo.headerIMG && businessInfo.headerIMG != undefined ? 
                    `url(${`https://app.opinanow.com/headerimg/${businessInfo.headerIMG}`})` : !!businessInfo.category ? 
                    `url(${require(`../../resources/images/categories/${businessInfo.category}.jpg`)})` : "" }}>
            </div>


            <TabContent activeTab={activeTab} className="experienceContent">
                <TabPane tabId="1">

                    <div className="publicInfo">
                        <h2 className="publicBN">
                            {businessInfo.businessName}
                        </h2>
                        <div className="d-flex publicRS">
                            <div className="publicRating">
                                {businessInfo.rating}
                            </div>
                            <div className="publicStars"> 
                                <Rating rating={businessInfo.rating} size="3" />
                            </div>
                        </div>
                        <div className="publicTR">
                            {businessInfo.user_ratings_total} {lang('total_reviews')}
                        </div>
                    </div>



                    <div className="experienceSubHeader">
                        <p>
                            <strong> {lang('help_us_improve_a')}</strong>{lang('share_with_us_your_experience')}
                        </p>
                    </div>

                    <hr/>
                    
                    <div className="container emojiClic mt-1 bg-white">
                        <div className="d-flex flex-column">
                            <h2 className="text-center">
                                {lang('how_was_your_experience_a')}
                            </h2>

                            <p className="text-center">({lang('touch_the_emoji_a')})</p>

                            <div className="d-flex justify-content-center mt-3 mb-5">
                            
                                <img 
                                    src={businessInfo.emojiName !== undefined ? require(`../../resources/images/emoji/gif/${businessInfo.emojiName}.gif`) : ""} 
                                    onClick={()=> _iOSDevice && businessInfo.facebookURL ? clickCount('showOption') : clickCount('positive')}
                                    className="mr-5" 
                                    alt=""/>

                                <img 
                                    src={Negative}
                                    onClick={()=> clickCount('negative')}
                                    style={{height: "55px", margin: "auto"}}
                                    alt="" />
                                    
                            </div>

                            <div className="d-flex flex-column text-center"> 
                
                                <img 
                                    src={LogoB}   
                                    className="experienceReviewsLogo" 
                                    alt=""/> 
                                
                                <span className="copyFooter">{`${lang('copyright')} © ${process.env.REACT_APP_SITE_TITLE} ${new Date().getFullYear()}`}</span>
                            </div>

                        </div>
                    </div>

                    <Modal
                        isOpen={modal.show}
                        toggle={() => setModal({show: false})}
                        scrollable={true}>

                        <ModalHeader toggle={() => setModal({show: false})}>
                            <h2>{modal.title}</h2>
                        </ModalHeader>

                        <ModalBody>
                        {
                            modal.mode === 1 ? 
                        
                                <form onSubmit={handleNegativeReview} noValidate>
                                    <p className="text-center">{`${lang('we_are_very_sorry')}`} <strong>{`${businessInfo.businessName}`}</strong>{`${lang('please_tell_us_how_to_improve')}`}</p>   
                    
                                    <div className="floatingLabel">
                                        <input 
                                            type="text"
                                            name="name" 
                                            autoComplete="off" 
                                            placeholder={lang('name_modal_n')}  
                                            //defaultValue="Anonimo"
                                            className={`${errors.name ? 'is-invalid' : ''} form-control`} />
                                        <label>{lang('name_modal_n')}</label>
                                        <div {...errors.name ? 'invalid' : ''} className="invalid-feedback">{errors.name}</div>
                                    </div>

                                    <div className="floatingLabel">
                                        <input 
                                            type="email"
                                            name="email" 
                                            autoComplete="off"
                                            placeholder={lang('email_modal_n')}  
                                            className={`${errors.email ? 'is-invalid' : ''} form-control`} />
                                        <label>{lang('email_modal_n')}</label>
                                        <div {...errors.email ? 'invalid' : ''} className="invalid-feedback">{errors.email}</div>
                                    </div>

                                    <FormGroup>
                                        
                                        <Input type="textarea" name="review" invalid={errors.review ? 'anything' : ''} rows="10" placeholder={lang('experience_modal_n')}/>
                                        <FormFeedback invalid={errors.review ? 'anything' : ''}>{errors.review}</FormFeedback>
                                    </FormGroup>

                                    <button type="submit"  className="btn btn-info">{lang('leave_review')}</button>
                                </form>
                            
                            : 

                            <div className="reviewsimgsContainer">
                                <button className="buttonToLink mr-3" onClick={()=> clickCount('positive')}>
                                    <img src={GoogleReviews} alt=""/>
                                    <span className="text-secondary">Google Negocio</span>
                                    </button>
                                <button className="buttonToLink ml-3"  onClick={()=> clickCount('facebook')}>
                                    <img src={FacebookReviews} alt=""/>
                                    <span className="text-secondary">Apple Maps</span>
                                    </button>
                            </div>

                        }
                        </ModalBody>
                    </Modal>

                </TabPane>

                <TabPane tabId="2">

                <div className="publicInfo-tab2">

                    {!!recommendation.title ?  

                        <div className="">
                            <h2 className="text-center text-dark mt-4">{recommendation.title}</h2>

                            <div className="mb-3">

                                <div className="d-flex recomendation1 recoment">
                                    <div className="contM">
                                        <h3 className="font-weight-bold mb-0">
                                        {recommendation.recommendation}                                 
                                        </h3>

                                        <Rating rating="5" size="1.5"/>
                                        <p>{recommendation.recommendationDescription}</p>
                                                                    
                                    </div>
                                    
                                    <div className="iconM">
                                        {
                                            !!recommendation.recommendationIcon1 ? 
                                            <img className="ml-1" src={recommendation.recommendationIcon1} width="80" height="80"/>
                                            :
                                            null
                                        }           

                                    </div>
                                </div>   

                                <div className="d-flex recomendation3 recoment">
                                    <div className="contM">

                                        <h3 className="font-weight-bold mb-0">
                                        {recommendation.recommendation2}
                                        </h3>

                                        <Rating rating="5" size="1.5"/>
                                        <p>{recommendation.recommendation2Description}</p>
                                    </div>

                                    <div className="iconM">
                                            {
                                                !!recommendation.recommendationIcon2 ? 
                                                <img className="ml-1" src={recommendation.recommendationIcon2} width="80" height="80"/>
                                                :
                                                null
                                            }
                                        </div>  
                                </div>

                                <div className="d-flex recomendation2 recoment">
                                    <div className="contM">

                                        <h3 className="font-weight-bold mb-0">
                                        {recommendation.recommendation1}
                                        </h3>
                                        <Rating rating="5" size="1.5"/>                                    
                                        <p>{recommendation.recommendation1Description}</p>

                                    </div>

                                    <div className="iconM">
                                        {
                                            !!recommendation.recommendationIcon3 ? 
                                            <img className="ml-1" src={recommendation.recommendationIcon3} width="80" height="80"/>
                                            :
                                            null
                                        }
                                    </div>  
                                </div>

                                <div className="d-flex recomendation4 recoment">
                                    <div className="contM">

                                        <h3 className="font-weight-bold mb-0">
                                        {recommendation.recommendation3}
                                        </h3>
                                        <Rating rating="5" size="1.5"/>                                    
                                        <p>{recommendation.recommendation3Description}</p>

                                    </div>

                                    <div className="iconM">
                                        {
                                            !!recommendation.recommendationIcon4 ? 
                                            <img className="ml-1" src={recommendation.recommendationIcon4} width="80" height="80"/>
                                            :
                                            null
                                        }
                                    </div>  
                                </div>
                            </div>
                        </div>
                    :
                    
                        <div className="mt-4 font-weight-bold text-center p-4">
                            <p>Bienvenido 🤗 al {businessInfo.businessName}.</p>
                            <p>Aún no tenemos las recomendaciones disponibles 😥</p>
                        </div>
                    }
                </div>


                </TabPane>

                <TabPane tabId="3">

                    <div className="publicInfo-tab3">

                        {_iOSDevice ? 

                            !!recommendation.catalog ? 
                       

                                <div className="">
                                    <div id="cartExp" className="d-flex justify-content-center pt-4 pb-3 descargaPdf">
                                        <span>
                                            ¿Problemas para ver la carta?&nbsp;
                                            <a href={`https://docs.google.com/viewerng/viewer?url=https://app.opinanow.com/catalog/${recommendation.catalog}&pid=explorer&efh=false&a=v&chrome=false`}> Clic aquí</a>
                                        </span>
                                        
                                    </div>  

                                    <iframe src={`https://docs.google.com/viewer?url=https://app.opinanow.com/catalog/${recommendation.catalog}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="100%" height="750px" frameborder="0" />
                                     
                                </div>
                            

                                :

                                <div className="mt-4 font-weight-bold text-center p-4">
                                <p>Bienvenido 🤗 al {businessInfo.businessName}.</p>
                                <p>La carta digital 🍽️ no está disponible. Por favor pídele la carta física al camarero.</p>
                            </div>

                            :

                            !!recommendation.catalog ? 

                     
                                <div className="">
                                    <div id="cartExp" className="d-flex justify-content-center pt-4 pb-3 descargaPdf">
                                        <span>
                                            ¿Problemas para ver la carta? 
                                            <a href={`https://docs.google.com/viewerng/viewer?url=https://app.opinanow.com/catalog/${recommendation.catalog}&pid=explorer&efh=false&a=v&chrome=false`}> Clic aquí</a>
                                        </span>
                                        
                                    </div>  

                                    <iframe src={`https://docs.google.com/viewer?url=https://app.opinanow.com/catalog/${recommendation.catalog}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="100%" height="750px" frameborder="0" />
                                     
                                </div>
                                                           
                             
                            
                            :
                            
                            <div className="mt-4 font-weight-bold text-center p-4">
                                <p>Bienvenido 🤗 al {businessInfo.businessName}.</p>
                                <p>La carta digital 🍽️ no está disponible. Por favor pídele la carta física al camarero.</p>
                            </div>
                    }

                    </div>
                </TabPane>
            </TabContent>

            {
                !!recommendation.title || !!recommendation.catalog ?
                    <div className="mobileMenuWrap">
                        <ul className="mobileMenu">
                            <li className="mobileMenuItem" onClick={() => setActiveTab('1')}>
                                <FontAwesomeIcon icon={faStarHalfAlt}/>
                                <span>Reseña</span>
                            </li>

                            {
                                !!recommendation.title ?
                                <li className="mobileMenuItem" onClick={() => setActiveTab('2')}>
                                    <FontAwesomeIcon icon={faGrinHearts}/>
                                    <span>Sugerencias</span>
                                </li>
                                : null
                            }

                            {
                                !!recommendation.catalog ? 

                                <li className="mobileMenuItem" onClick={() => setActiveTab('3')}>
                                    <a href="#cartExp" className="cartaScroll">
                                        <FontAwesomeIcon icon={faUtensils}/>
                                        <span>Carta</span>
                                    </a>
                                </li>

                                : null
                            }
                            
                            
                        </ul>
                    </div>

                : null
            }

        </div>

        )

}

export default Experience;