import {EZFETCH} from '../fetch';

let API_KEY = "30b2c6225562bf3479e048dacaa25c1f4ddb49a8";
let token;

async function getToken()
{
  let headers = {
    'Content-Type':'application/json',
    'Accept':'application/json' };


  try
  {

    return await EZFETCH.post(
      'https://api.flaticon.com/v2/app/authentication', 
      {apikey: API_KEY}, 
      headers, 'omit');
  } 
  catch(e)
  {
    throw e;
    
    //"The requested resource couldn’t be obtained."
    //"An API key is needed to log in. Please try again with a valid API key."
    
  }
}


async function getIcons()
{
  
  try
  {
    if(!token)
    {
      let resp = await getToken();

      token = resp.data.token;
    }
    

    let myHeaders = {
      'Accept':'application/json',
      'Authorization': `Bearer ${token}` 
    };

    let response = await EZFETCH.get('https://api.flaticon.com/v2/search/icons', {q: "sushi"}, myHeaders, 'omit');

    return response;
  }
  catch(e)
  {
    throw e;
  }
}


async function searchIcon(search)
{
  try
  {
    if(!token)
    {
      let resp = await getToken();

      token = resp.data.token;
    }
    

    let myHeaders = {
      'Accept':'application/json',
      'Authorization': `Bearer ${token}` 
    };

    let response = await EZFETCH.get('https://api.flaticon.com/v2/search/icons', {q: search}, myHeaders, 'omit');

    return response;
  }
  catch(e)
  {
    throw e;
  }
}


export const flaticon = {
  searchIcon,
  getIcons
}