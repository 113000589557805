import React, { useContext, useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {EZFETCH} from '../../resources/modules/fetch';
import {getRecaptchaV3Token} from '../../resources/modules/recaptcha';
import {LoadingContext, userInfoContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import Logo from "../../resources/images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';


function Login ()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setUserInfo] = useContext(userInfoContext);
    const [, setAlert] = useContext(AlertContext);

    //Local States
    const [errors, setErrors] = useState({}); //init as empty object
    const [inputType, setInputType] = useState('password');
    
    //Browser History
    const history = useHistory();


    useEffect(()=> {

        //Param message
        if(history.location.message !== undefined)
        {
            setAlert({
                position: 'topRightAlert',
                message: history.location.message,
                color: 'success', 
                show: true                
                }
            );
        };

    }, [history.location.message]);
    

    async function handleLogin (post)
    {
        post.preventDefault();
        
        if(isLoading) return;

        setLoading(true);

        let postParams = {
            username: post.target.username.value, 
            password: post.target.password.value, 
            recaptchaResponse: await getRecaptchaV3Token()
        };

        try
        {
            let response = await EZFETCH.post(
                `${process.env.REACT_APP_SERVER_URL}/user/login`,
                postParams
            );

            localStorage.clear();

            setUserInfo({isLogged: true, details: response.userInfo});

            //history.push('/');

            window.location.reload();
        }
        catch (error)
        {
            if(error.message === "Email_verification_required")
            {
                localStorage.setItem('userEmail', error.email);

                history.push('/emailverification');
            }

            setErrors(error);
        }
        finally 
        {
            setLoading(false);
        }
    }
    

    return (

        <form onSubmit={handleLogin} noValidate>

            <a href="https://opinanow.com">
                <img 
                src={Logo} 
                alt="" 
                className="img-fluid mb-3 align-self-center"/>
            </a>

            <h5 className="text-center text-white m-3">
                {lang('login_title')}
            </h5>

            <div className="form-group">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                        </span>
                    </div>
                    <input 
                        type="text"
                        name="username" 
                        placeholder={lang('username_or_email')}  
                        className={`${errors.username ? 'is-invalid' : ''} form-control rounded-right border-0 shadow-none`} />
                    <div {...errors.username ? 'invalid' : ''} className="invalid-feedback">{errors.username}</div>
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                        <FontAwesomeIcon icon={faKey} />
                        </span>
                    </div>
                    <input 
                        type={inputType} 
                        name="password" 
                        placeholder={lang('password')}  
                        className={`${errors.password ? 'is-invalid' : ''} form-control border-left-0 border-0 shadow-none`} />


                        <div className="input-group-prepend">
                            <span 
                                className="input-group-text rounded-right bg-white border-0" 
                                onClick={() => inputType === 'password' ? setInputType('text') : setInputType('password')}>  
                                {inputType === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>

                    <div {...errors.password ? 'invalid' : ''} className="invalid-feedback">{errors.password}</div>
                </div>
            </div>
        
            <div className="text-center d-flex flex-column">

                <button className="btn btn-info btn-lg"  type="submit">
                    <FontAwesomeIcon icon={faUser} className="mr-2"/>
                    {lang('login')}
                </button>

                <Link to="/resetpsw" className="mt-5 mb-2 text-white">
                    {lang('forgot_password')}
                </Link>

                <Link to="/register" className="mb-2 text-white">
                    {lang('signup')}
                </Link>
            </div>
        </form>
    )
}

export default Login;