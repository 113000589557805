import React, {useContext, useState, useEffect} from 'react';
import {LoadingContext, AlertContext} from '../../store';
import {lang} from '../../resources/languages';
import {EZFETCH} from '../../resources/modules/fetch';
import '../../resources/css/global.css';

function Mybusiness()
{
    //Global states
    const [isLoading, setLoading] = useContext(LoadingContext);
    const [, setAlert] = useContext(AlertContext);

    //Local States
    const [businessInfo, setBusinessInfo] = useState({});

    //componentDidMount
    useEffect( () => {

        async function getBusinessInfo()
        {
            if(isLoading) return;

            setLoading(true);

            try
            {
                let response = await EZFETCH.get(`${process.env.REACT_APP_SERVER_URL}/business/list`);

                setBusinessInfo(response);
            }
            catch(errors)
            {
                setAlert({
                    visible: true,
                    position: 'topRightAlert',
                    message: lang('something_went_wrong'),
                    color: 'danger'
                });
            }
            finally
            {
                setLoading(false);
            }
        }
    
        getBusinessInfo();

    }, []);

    


    return (

        <div className="headerSpace card shadow mt-5">
            <div className="titleBg card-header">
                <h1 className="font-weight-bold">
                    {lang('my_business')}
                </h1>
            </div>

            <div className="card-body min-vh-100">                    
                <h4>
                    {lang('google_my_business_details')}
                </h4>

                <div className="row">
                    <div className="col-sm-6">
                        <h2 className="border-bottom border-info mt-3 mb-4">
                            {lang('data')}
                        </h2>

                        <p className="border-bottom border-light font-weight-bold">
                            {lang('name')}: 
                            <span className="ml-2 font-weight-normal">
                                {businessInfo.name}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                                {lang('address')}:
                            <span className="ml-2 font-weight-normal">
                                {businessInfo.address}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            {lang('website')}: 
                            <span className="ml-2 font-weight-normal">
                                <button onClick={() => window.open(businessInfo.website, "_blank")} className="buttonToLink text-info">
                                    {lang('go_to_web')}
                                </button>
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            {lang('phone')}: 
                            <span className="ml-2 font-weight-normal">
                                {businessInfo.number}
                            </span>
                        </p>
                    </div>


                    <div className="col-sm-6">
                        <h2 className="border-bottom  border-info mt-3 mb-4">
                            {lang('business_hours')}
                        </h2>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.monday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.tuesday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.wednesday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.thursday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.friday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.saturday}
                            </span>
                        </p>

                        <p className="border-bottom border-light font-weight-bold">
                            <span className="ml-2 font-weight-normal text-capitalize">
                                {businessInfo.sunday}
                            </span>
                        </p>

                    </div>
                </div>

                <div className="text-center mt-5">
                    <p>
                        {lang('to_modify_please_click_here')}
                    </p>
                    <button onClick={() => window.open("https://www.google.com/intl/es_es/business/")}  className="btn btn-info">
                        {lang('visit_google_my_business')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Mybusiness;