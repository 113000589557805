import React, {useEffect, useContext} from 'react';
import {isUserLogged} from './functions/user';
import {currentLanguage} from '../resources/languages';
import {userInfoContext, LoadingContext} from '../store';
import {loadReCaptchaV3} from '../resources/modules/recaptcha';

//Components
import Dashboard from './Dashboard';
import Public from './public';


function App()
{ 
    const [userInfo, setUserInfo] = useContext(userInfoContext);
    const [isLoading, setLoading] = useContext(LoadingContext);

    //componentDidMount
    useEffect( () => {

        window.addEventListener("load",  pageLoad);

        //component will unmount
        return () => {
        
            window.removeEventListener("load", pageLoad);
        }

    }, []);

    useEffect( () => {

        loadReCaptchaV3();//load recaptcha 

    }, []);

    

    const pageLoad = async () =>{
        
        setLoading(true);

        await currentLanguage();

        let currentUser =  await isUserLogged();   

        setUserInfo(currentUser);

        setLoading(false);
    };


    if(userInfo.isLogged === true && window.location.pathname !== "/experience")
    {
        
        return (
            <Dashboard/>
        )
    }
    else
    {
        return (
            <Public/>
        )
    }
}

export default App;