/**
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 * Xtreme Software Solutions LLC @2020
 * FrontEnd Fetch ajax Module v0.1
 * Coded By @jerryurenaa
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 */


/**
 * default headers
 */

 let apiHeaders = {
    'Accept':       'application/json',
    'Content-Type': 'application/json'
};




/**
 * @method post
 * @param string reqURL 
 * @param json params 
 * @param json headers //optional
 * @return json response
 */
async function post(reqURL, params, headers = apiHeaders, credentials = 'include')
{
    const options = {
        method: "POST",
        origin: reqURL,
        credentials: credentials, // include, *same-origin, omit
        body: JSON.stringify(params),
        headers: headers
    };

    const response = await fetch(reqURL, options);

    if(response.status >= 400)
    {
        throw await response.json();
    }
  
    return await response.json();
}


/**
 * @method get
 * @param string reqURL 
 * @param json params 
 * @param json headers //optional
 * @return json response
 */
async function get(reqURL, params = null,  headers = apiHeaders, credentials = 'include')
{
    const options = {
        method: "GET",
        credentials: credentials, // include, *same-origin, omit
        headers: headers
    };

    const req = params !== null ? "?" + _objectToQueryString(params) : "";

    const response = await fetch(reqURL + req , options);

    if(response.status >= 400)
    {
        throw await response.json();
    }
  
    return await response.json();
}


/**
 * @method _objectToQueryString
 * @param {*} obj 
 * convert object to query strings
 */
function _objectToQueryString(obj) 
{
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}



export const EZFETCH = {
    post,
    get
};


